import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';

export default function DMCAPolicy() {
  const policySections = [
    {
      title: "Introduction",
      content: "Mindscape values and upholds the intellectual property rights of others, and we are dedicated to adhering to the Digital Millennium Copyright Act (DMCA) and other relevant copyright laws. This DMCA Policy delineates the steps we take to address notifications of copyright infringement and guides reaching out to us if you suspect that your copyrighted material has been utilized on our platform without proper authorization."
    },
    {
      title: "Reporting Copyright Infringement",
      content: "If you believe in good faith that materials transmitted or created through our Website, App(s) or Services infringe your copyright, you (or your agent) may send us a notice requesting that we remove the material or block access to it. Please provide the following information in writing: 1) An electronic or physical signature of the copyright owner (or authorized agent); 2) A description of the copyrighted work and information to locate it; 3) Your contact information; 4) A statement of your good-faith belief that the use is not authorized; 5) A statement, under penalty of perjury, that the information in your notice is accurate and that you are authorized to act on behalf of the copyright owner."
    },
    {
      title: "DMCA Notices Response",
      content: "After receiving a complete infringement notice, we will: 1) Review and confirm that received documents meet DMCA requirements; 2) Take proper preliminary actions against said alleged infringement within 1-3 days, including without limitation link blockage; 3) Notify the alleged infringer and demand an explanation and counter evidence."
    },
    {
      title: "Counter Notification",
      content: "If you believe someone has wrongly filed a notice of copyright infringement against you, you may send us a counter-notice. We will then notify the alleged claimant and hold the process for 10-14 days. We will re-enable your content unless the copyright owner initiates legal action against you before then."
    },
    {
      title: "Contact Information",
      content: "Notices and counter-notices should be sent to us via email at: Support. We are committed to addressing concerns in a timely manner and ensuring a positive experience for all our users."
    },
    {
      title: "Termination",
      content: "We reserve the right to suspend or terminate the use of our platform by anyone engaged in suspected infringement as described in this policy."
    }
  ];

  return (
    <>
      <DashboardLayout title={'DMCA Policy'}>
        <div style={{
          maxWidth: '100%',
          margin: '0 auto',
          padding: '20px',
          fontFamily: 'Arial, sans-serif',
          backgroundColor: '#121212a0',
        }}>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            Last updated: {new Date().toLocaleDateString()}
          </p>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            This DMCA Policy outlines Mindscape's procedures for addressing copyright infringement claims 
            and protecting intellectual property rights on our platform.
          </p>
          {policySections.map((section, index) => (
            <div key={index} style={{
              marginBottom: '20px',
              padding: '15px',
              borderRadius: '5px'
            }}>
              <h2 style={{
                color: '#fff',
                fontSize: '20px',
                marginBottom: '10px',
                fontWeight: 'bold'
              }}>{section.title}</h2>
              <p style={{
                fontSize: '14px',
                lineHeight: '1.5',
                color: '#fff'
              }}>{section.content}</p>
            </div>
          ))}
          <p style={{
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '30px',
            color: '#fff'
          }}>
            By using Mindscape, you acknowledge and agree to the terms outlined in this DMCA Policy.
          </p>
        </div>
      </DashboardLayout>
    </>
  )
}