import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';

export default function CommunityGuidelines() {
  const guidelines = [
    {
      title: "Respect and Responsibility",
      content: "Our platform thrives on mutual respect. Adhere to these guidelines, applicable laws, and our Terms of Service to ensure a positive experience for all. We reserve the right to take action against violations, including account restrictions or termination."
    },
    {
      title: "Age Requirement",
      content: "Our platform is designed for users 18 and older. Younger individuals are not permitted to use our services."
    },
    {
      title: "Legal Compliance",
      content: "Engage only in lawful activities. Any form of illegal conduct, including but not limited to exploitation, trafficking, or promotion of dangerous activities, is strictly prohibited."
    },
    {
      title: "Platform Integrity",
      content: "Do not attempt to compromise our platform's functionality through malware, viruses, or any form of malicious software."
    },
    {
      title: "Inclusive Environment",
      content: "We have zero tolerance for hate speech or content that promotes discrimination based on race, ethnicity, religion, disability, gender, age, veteran status, sexual orientation, or gender identity."
    },
    {
      title: "Truthful Communication",
      content: "Avoid spreading misinformation that could lead to violence, physical harm, or disruption of democratic processes."
    },
    {
      title: "Privacy Protection",
      content: "Respect others' privacy. Do not share personal or confidential information about other users, including images, financial data, or private contact details."
    },
    {
      title: "Account Security",
      content: "Unauthorized access to others' accounts and phishing attempts are forbidden."
    },
    {
      title: "Child Safety",
      content: "We have a strict policy against any content involving minors in inappropriate contexts. This includes both real and AI-generated imagery. Violations will be reported to relevant authorities."
    },
    {
      title: "Content Quality",
      content: "Avoid spamming, including unsolicited marketing or bulk messaging."
    },
    {
      title: "Appropriate Content",
      content: "Refrain from posting explicit sexual content, nudity, or extreme violence. Content promoting self-harm, eating disorders, or substance abuse is not allowed."
    },
    {
      title: "Authentic Behavior",
      content: "Do not impersonate others or engage in deceptive practices. Multiple accounts and soliciting funds from other users are prohibited."
    },
    {
      title: "Intellectual Property",
      content: "Respect copyrights and trademarks. Ensure you have necessary permissions before sharing content."
    },
    {
      title: "Prohibited Transactions",
      content: "Do not use our platform to sell or promote restricted goods such as firearms, alcohol, or adult products."
    },
    {
      title: "Community Engagement",
      content: "Organizing contests or promotions without authorization is not allowed. Refrain from commercial solicitations."
    },
    {
      title: "Reporting Violations",
      content: "If you encounter content or behavior that violates these guidelines, please report it to our support team. Your participation in maintaining our community standards is crucial."
    }
  ];

  return (
    <>
      <DashboardLayout title={'Community Guidelines'}>
        <div style={{
          maxWidth: '100%',
          margin: '0 auto',
          padding: '20px',
          fontFamily: 'Arial, sans-serif',
          backgroundColor: '#121212a0',
        }}>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            These guidelines are designed to foster a safe, respectful, and enriching environment for all our users. 
            Please take a moment to familiarize yourself with our expectations for community conduct.
          </p>
          {guidelines.map((guideline, index) => (
            <div key={index} style={{
              marginBottom: '20px',
              padding: '15px',
              borderRadius: '5px'
            }}>
              <h2 style={{
                color: '#fff',
                fontSize: '20px',
                marginBottom: '10px',
                fontWeight: 'bold'
                
              }}>{guideline.title}</h2>
              <p style={{
                fontSize: '14px',
                lineHeight: '1.5',
                color: '#fff'
              }}>{guideline.content}</p>
            </div>
          ))}
          <p style={{
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '30px',
            color: '#fff'
          }}>
            Thank you for being a valued member of our community and for your commitment to these guidelines.
          </p>
        </div>
      </DashboardLayout>
    </>
  )
}