export default function LargeCloseIcon () {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Landing-Page-–-Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.8">
        <g id="Menu" transform="translate(-35.000000, -35.000000)" fill="#fff">
          <g id="Button-Enabled-Copy" transform="translate(20.000000, 20.000000)">
            <path d="M32.4246212,15.4540585 L34.5459415,17.5753788 L27.1210585,25.0000585 L34.5459415,32.4246212 L32.4246212,34.5459415 L25.0000585,27.1210585 L17.5753788,34.5459415 L15.4540585,32.4246212 L22.8790585,25.0000585 L15.4540585,17.5753788 L17.5753788,15.4540585 L25.0000585,22.8790585 L32.4246212,15.4540585 Z" id="Combined-Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
