import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';

export default function ComplianceStatement() {
  return (
    <>
      <DashboardLayout title={'2257 Record-Keeping Requirements Compliance Statement'}>
        <div style={{
          maxWidth: '100%',
          margin: '0 auto',
          padding: '20px',
          fontFamily: 'Arial, sans-serif',
          backgroundColor: '#121212a0',
          color: '#fff'
        }}>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            marginBottom: '20px'
          }}>
            Last modified: {new Date().toLocaleDateString()}
          </p>
          <p style={{
            fontSize: '14px',
            lineHeight: '1.5',
            marginBottom: '20px'
          }}>
            All visual depictions displayed on Mindscape are exempt from the provision of 18 U.S.C. §2257, 2257A and/or 28 C.F.R. 75 because:
          </p>
          <ol style={{
            fontSize: '14px',
            lineHeight: '1.5',
            marginLeft: '20px',
            marginBottom: '20px'
          }}>
            <li>The content does not depict real individuals engaging in explicit sexual activities.</li>
            <li>All visual content is artificially created using advanced AI technology.</li>
            <li>The platform does not utilize or display images of actual persons in any capacity.</li>
          </ol>
          <p style={{
            fontSize: '14px',
            lineHeight: '1.5',
            marginBottom: '20px'
          }}>
            Mindscape is dedicated to presenting only AI-generated content that adheres to all relevant legal and regulatory standards. Our AI-generated imagery is produced and delivered in strict compliance with ethical guidelines, ensuring no real individuals are involved or depicted.
          </p>
          <p style={{
            fontSize: '14px',
            lineHeight: '1.5',
            marginBottom: '20px'
          }}>
            For any questions or additional information regarding our compliance with 2257 regulations, please reach out to our legal team at <strong>Support</strong>.
          </p>
        </div>
      </DashboardLayout>
    </>
  )
}