export default function formatNumber(num) {
  if (num < 1000) {
    return num.toString();
  }

  const units = ['', 'k', 'M'];
  const unit = Math.floor((num.toFixed(0).length - 1) / 3);
  let formatted = (num / Math.pow(1000, unit)).toFixed(2);

  // Remove trailing zeros after decimal point
  formatted = formatted.replace(/\.?0+$/, '');

  return formatted + units[unit];
}