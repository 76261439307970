import React, { useEffect, useState, useRef } from 'react';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/Categories.module.scss';
import { useNavigate } from 'react-router-dom';

const subcategories = {
  "Character Type": [
    { emoji: "👩‍🦰", name: "Female" },
    { emoji: "🔥", name: "NSFW" },
    { emoji: "🪢", name: "Scenario" },
    { emoji: "⛓️", name: "Dominant" },
    { emoji: "🙇", name: "Submissive" }
  ],
  "Genre": [
    { emoji: "💥", name: "Action" },
    { emoji: "🎮", name: "Game" },
    { emoji: "📚", name: "Books" },
    { emoji: "📙", name: "Philosophy" },
    { emoji: "🎥", name: "Movie" },
    { emoji: "🌎", name: "Non-English" },
    { emoji: "🧜‍♀️", name: "Myth" },
    { emoji: "🦸‍♂️", name: "Hero" }
  ],
  "Personality": [
    { emoji: "😊", name: "Deredere" },
    { emoji: "😡", name: "Tsundere" },
    { emoji: "🔪", name: "Yandere" },
    { emoji: "🥶", name: "Kuudere" },
    { emoji: "😶", name: "Dandere" }
  ],
  "Goal": [
    { emoji: "👽", name: "Erotic Roleplay" },
    { emoji: "👽", name: "Breeding" },
    { emoji: "👽", name: "Femdom" },
    { emoji: "💁", name: "Assistant" },
    { emoji: "🧑‍🎨", name: "Original Character (OC)" },
    { emoji: "👭", name: "Multiple" }
  ],
  "Origin": [
    { emoji: "👩", name: "Original Character (OC)" },
    { emoji: "🎮", name: "Game" },
    { emoji: "🎥", name: "Movie" },
    { emoji: "👩", name: "VTuber" },
    { emoji: "📚", name: "Books" },
    { emoji: "👽", name: "Folklore" }
  ],
  "Physical Traits": [
    { emoji: "🦄", name: "Non-human" },
    { emoji: "👧", name: "Monster Girl" },
    { emoji: "🍔", name: "BBW" },
    { emoji: "📏", name: "Petite" },
    { emoji: "🧖🏼‍♀️", name: "Giant" },
    { emoji: "🧝‍♀️", name: "Elf" },
    { emoji: "🤖", name: "Robot" },
    { emoji: "🗿", name: "Object" }
  ],
  "Sexuality": [
    { emoji: "🌈", name: "Non-binary" },
    { emoji: "👩‍❤️‍👩", name: "Lesbian" },
    { emoji: "🚹", name: "Straight" },
    { emoji: "🌈", name: "Asexual" },
    { emoji: "💔", name: "Cheating" }
  ],
  "Fantasy": [
    { emoji: "🔮", name: "Magical" },
    { emoji: "🦹‍♂️", name: "Villain" },
    { emoji: "🦸‍♂️", name: "Hero" },
    { emoji: "🧝‍♀️", name: "Elf" },
    { emoji: "👑", name: "Royalty" },
    { emoji: "👽", name: "Alien" },
    { emoji: "👹", name: "Monster" },
    { emoji: "👿", name: "Succubus" }
  ],
  "Kink": [
    { emoji: "⛓️", name: "Chastity" },
    { emoji: "🔥", name: "NSFW" },
    { emoji: "🪢", name: "Scenario" },
    { emoji: "⛓️", name: "Dominant" },
    { emoji: "🙇", name: "Submissive" },
    { emoji: "👽", name: "Erotic Roleplay" },
    { emoji: "👽", name: "Breeding" },
    { emoji: "👽", name: "Femdom" },
    { emoji: "🦹‍♂️", name: "Villain" },
    { emoji: "🦸‍♂️", name: "Hero" },
    { emoji: "👧", name: "Monster Girl" },
    { emoji: "🧑‍🎨", name: "Original Character (OC)" }
  ],
  "Ethnicity": [
    { emoji: "🤍", name: "White" },
    { emoji: "🕌", name: "Arab" },
    { emoji: "⚫️", name: "Black" },
    { emoji: "💃", name: "Latina" }
  ],
  "Religion": [
    { emoji: "🕌", name: "Muslim" },
    { emoji: "⛪️", name: "Religion" },
    { emoji: "📜", name: "Politics" },
    { emoji: "👩‍🎨", name: "Seinen" }
  ]
};

const categories = Object.keys(subcategories);

export default function CategoriesMain() {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(true);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const categoriesRef = useRef(null);

  const visibleCategories = showAllCategories ? categories : categories.slice(0, 5);

  const getScrollableParent = (element) => {
    if (element === null) return document.documentElement;
    if (element.scrollHeight > element.clientHeight) {
      return element;
    } else {
      return getScrollableParent(element.parentElement);
    }
  };

  const scrollToCategory = (category) => {
    const element = document.getElementById(category);
    if (element && categoriesRef.current) {
      const scrollableParent = getScrollableParent(categoriesRef.current);
      const topOffset = element.offsetTop - categoriesRef.current.offsetTop;
      scrollableParent.scrollTo({
        top: topOffset,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    if (categoriesRef.current) {
      const scrollableParent = getScrollableParent(categoriesRef.current);
      scrollableParent.scrollTop = 0;
    }
  }, []);

  return (
    <>
      {!loggedIn ? (
        <></>
      ) : (
        <DashboardLayout
          title={'Find the perfect AI Hottie'}
          subtitle={'Browse our extensive collection of AI personalities, backgrounds, and traits to discover a companion that fits your unique preferences.'}
        >
          <div ref={categoriesRef}>
            <div className={styles.jumpToCategory}>
              <h3 style={{ color: '#fff', fontWeight: 'bold' }}>Jump to category</h3>
              <div className={`${styles.row} ${styles.scrollableRow}`}>
                <div className={styles["filterSection"]}>
                  {visibleCategories.map(category => (
                    <button
                      key={category}
                      className={styles.filterButton}
                      onClick={() => scrollToCategory(category)}
                    >
                      {category}
                    </button>
                  ))}
                  <button
                    className={styles["showMoreButton"]}
                    onClick={() => setShowAllCategories(!showAllCategories)}
                  >
                    {showAllCategories ? "Show less" : "Show more"}
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.categories}>
              {Object.keys(subcategories).map((category, index) => (
                <div key={index} className={styles.category} id={category}>
                  <h2 style={{ color: '#fff', fontWeight: 'bold' }}>{category}</h2>
                  <div className={styles.subcategories}>
                    {subcategories[category].map((subcat, idx) => (
                      <div 
                        key={idx} 
                        className={styles.subcategoryCard}
                        onClick={e => {
                          e.preventDefault();
                          navigate(`/tag/${subcat.name.toLowerCase()}`)
                        }}
                      >
                        <div className={styles.cardHeader}>
                          <h3 className={styles.emoji}>{subcat.emoji}</h3>
                          <h3>{subcat.name}</h3>
                        </div>
                        <div className={styles.images}>
                          <img src="/placeholder.avif" alt="Character 1" />
                          <img src="/placeholder.avif" alt="Character 2" />
                          <img src="/placeholder.avif" alt="Character 3" />
                          <img src="/placeholder.avif" alt="Character 4" />
                          <div className={styles.more}>99+</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </DashboardLayout>
      )}
    </>
  );
}
