import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import Header from "../components/Header/Header";

import styles from "../assets/pages/Signup.module.scss";

import Input from '../components/Input/Input';
import Button from '../components/Button/Button';

import { containsNumber, containsCapitalLetter, isValidEmail } from '../utils/regex';
import axiosRequest from '../utils/request';

import DashboardLayout from '../components/DashboardLayout/DashboardLayout';


export default function Signup() {
  const location = useLocation();
  const [initialLoad, setInitialLoad] = useState(true);
  const [step, setStep] = useState(2);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [username, setUsername] = useState('');
  const [ref, setRef] = useState('');
  const [isAdmin, setIsAdmin] = useState(true);

  const [step0Text, setStep0Text] = useState('Welcome to Mindscape!');
  const [step1Text, setStep1Text] = useState('Lets get started.');
  const [step2Text, setStep2Text] = useState('→ Enter your email');
  const [step3Text, setStep3Text] = useState('→ Create a password');
  const [step4Text, setStep4Text] = useState('→ Confirm your password');
  const [step5Text, setStep5Text] = useState('→ Username');

  const [passwordMsg, setPasswordMsg] = useState('Password is too short!');
  const [passwordMsgStyle, setPasswordMsgStyle] = useState({ margin: '5px', color: 'red' });
  const [div1Style, setDiv1Style] = useState({});
  const [div2Style, setDiv2Style] = useState({});
  const [div3Style, setDiv3Style] = useState({});

  const [showRegister, setRegister] = useState(true);

  const [passwordValid, setPasswordValid] = useState(false);

  const [redirectPath, setRedirectPath] = useState()

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state && location.state.redirectPath) {
      const fullPath = location.state.redirectPath;
      setRedirectPath(fullPath);
    }
  }, [location]);

  useEffect(() => {
    /*if (initialLoad) {
      setTimeout(async () => {
        loadInitialText();
      }, 500);
      setInitialLoad(false);
    };*/

    async function loadInitialText() {
      await animateTyping('Welcome to Mindscape!', setStep0Text);
      setTimeout(() => {
        setStep(1);
        //animateTyping('Lets get started.', setStep1Text);
      }, 1000);

      setTimeout(() => {
        setStep(2);
      }, 2000)

      setTimeout(() => {
        //animateTyping('→ Enter your email', setStep2Text, 12.5);
      }, 2250)
    }
  }, []);

  useEffect(() => {
    if (step === 3 || step === 4) {
      validatePassword(step === 3 ? password : confirmedPassword);
    }
  }, [password, confirmedPassword, step]);

  useEffect(() => {
    const url = window.location.href;
    const params = new URLSearchParams(new URL(url).search);
    const invCode = params.get('ref');
    if (invCode) {
      setRef(invCode);
    };
  }, []);

  const validatePassword = (pwd) => {
    setPasswordValid(false);
    if (pwd.length < 8) {
      setPasswordMsg('Password is too short!');
      setPasswordMsgStyle({ margin: '5px', color: 'red' });
      setDiv1Style({ backgroundColor: '#b2b2b23c' });
      setDiv2Style({ backgroundColor: '#b2b2b23c' });
      setDiv3Style({ backgroundColor: '#b2b2b23c' });
      return;
    }

    setDiv1Style({ backgroundColor: 'green' });

    if (!containsCapitalLetter(pwd)) {
      setPasswordMsg('Must contain a capital!');
      setPasswordMsgStyle({ margin: '5px', color: 'red' });
      setDiv2Style({ backgroundColor: '#b2b2b23c' });
      setDiv3Style({ backgroundColor: '#b2b2b23c' });
      return;
    }

    setDiv2Style({ backgroundColor: 'green' });

    if (!containsNumber(pwd)) {
      setPasswordMsg('Must contain a number!');
      setPasswordMsgStyle({ margin: '5px', color: 'red' });
      setDiv3Style({ backgroundColor: '#b2b2b23c' });
      return;
    }

    setDiv3Style({ backgroundColor: 'green' });

    if (step === 4 && pwd !== password) {
      setPasswordMsg("Passwords don't match!");
      setPasswordMsgStyle({ margin: '5px', color: 'red' });
      return;
    }

    setPasswordMsg('Password looks good!');
    setPasswordMsgStyle({ margin: '5px', color: 'green' });
    setPasswordValid(true);
  }

  async function animateTyping(inputText, setStateFunction, delay = 10) {
    let index = -1;

    const typeCharacter = () => {
      if (index < inputText.length) {
        setStateFunction(prevState => prevState + inputText.charAt(index));
        index++;
        setTimeout(typeCharacter, delay);
      }
    };

    typeCharacter();
  };

  async function register() {
    try {
      if (!email || !password || !username) {
        toast.error('Must fill out all fields!');
        return;
      }

      let result = await axiosRequest('post', 'signup', { email, password, username, ref });

      if (result.data.success) {
        if (!redirectPath) {
          navigate('/verify', { state: { email } });
        } else {
          navigate('/verify', { state: { email, redirectPath } });
        }
      } else {
        toast.error(result.data.msg);
      }
    } catch (e) {
      console.log('Error at sign up call -> ', e);
      toast.error('Unknown error signing up.');
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      switch (step) {
        case 2:
          if (isValidEmail(email)) {
            //animateTyping('→ Create a password', setStep3Text);
            setStep(3);
          } else {
            toast.error('Invalid email format!');
          }
          break;
        case 3:
          if (passwordValid) {
            setStep(4);
            //animateTyping('→ Confirm your password', setStep4Text);
          }
          break;
        case 4:
          if (passwordValid) {
            setStep(5);
            //animateTyping("→ Username", setStep5Text);
          }
          break;
        case 5:
          if (passwordValid && username) {
            register();
          }
          break;
      }
    }
  };

  return (
    <DashboardLayout>
      <div className={styles.loginPage} >
        <div className={styles.container}>
          <div className={styles.card}>
            <div className={styles.text}>
              <p>{step0Text} {" "} {step === 0 ? <span className={styles.blinker} /> : ""}</p>
            </div>
            <div className={styles.text}>
              <p>{step1Text} {" "} {step >= 1 ? <span className={styles.blinker} /> : ""}</p>
            </div>
            {step >= 2 && (
              <div className={styles.inputLabel}>
                <Input
                  style={{ width: '100%', backgroundColor: '' }}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onKeyPress={handleKeyPress}
                  label={step < 3 ? step2Text : "✓ Enter your email"}
                  titleWhite
                  id={'email'}
                  type={'email'}
                />
                {step < 3 && (
                  <Button
                    style={{ marginTop: '12px', width: '100%' }}
                    onClick={() => {
                      if (isValidEmail(email)) {
                        setStep(3);
                        //animateTyping('→ Create a password', setStep3Text);
                      } else {
                        toast.error('Invalid email format!');
                      }
                    }}
                  >
                    Continue
                  </Button>
                )}
              </div>
            )}
            {step >= 3 && (
              <div className={styles.inputLabel}>
                <Input
                  style={{ width: '100%' }}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  onKeyPress={handleKeyPress}
                  label={step < 4 ? step3Text : "✓ Create a password"}
                  id={'password'}
                  type={'password'}
                  titleWhite
                />
                {step < 4 && (
                  <Button
                    style={passwordValid ? { marginTop: '12px', width: '100%' } : { marginTop: '12px', width: '100%', opacity: '0.5' }}
                    onClick={() => {
                      setStep(4);
                      //animateTyping('→ Confirm your password', setStep4Text);
                    }}
                    disabled={!passwordValid}
                  >
                    Continue
                  </Button>
                )}
              </div>
            )}
            {step >= 4 && (
              <div className={styles.inputLabel}>
                <Input
                  style={{ width: '100%' }}
                  value={confirmedPassword}
                  onChange={e => setConfirmedPassword(e.target.value)}
                  onKeyPress={handleKeyPress}
                  label={step4Text}
                  id={'confirmedPassword'}
                  type={'password'}
                  titleWhite
                />
                {showRegister && step < 5 && (
                  <Button
                    style={passwordValid ? { marginTop: '12px', width: '100%' } : { marginTop: '12px', width: '100%', opacity: '0.5' }}
                    onClick={() => {
                      setStep(5);
                      //animateTyping("→ Username", setStep5Text);
                    }}
                    disabled={!passwordValid}
                  >
                    Continue
                  </Button>
                )}
              </div>
            )}
            {step >= 5 && (
              <div className={styles.inputLabel}>
                <Input
                  style={{ width: '100%' }}
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  onKeyPress={handleKeyPress}
                  label={step5Text}
                  id={'username'}
                  type={'text'}
                  titleWhite
                />
                {showRegister && (
                  <Button
                    style={passwordValid ? { marginTop: '12px', width: '100%' } : { marginTop: '12px', width: '100%', opacity: '0.5' }}
                    onClick={register}
                    disabled={!passwordValid}
                  >
                    Register
                  </Button>
                )}
              </div>
            )}
            {step >= 3 && (
              <div>
                <div style={{ marginTop: '12px' }} className={styles.row}>
                  <div style={div1Style} className={styles.Jc3fE}></div>
                  <div style={div2Style} className={styles.Jc3fE}></div>
                  <div style={div3Style} className={styles.Jc3fE}></div>
                </div>
                <div style={passwordMsgStyle}>
                  <h5>{passwordMsg}</h5>
                </div>
                <div style={{ marginLeft: '5px', marginTop: '25px', color: '#fff' }}>
                  <small>Make sure it's at least 8 characters including a capital and number!</small>
                </div>
              </div>
            )}
          </div>
          {step >= 4 && (
            <div style={{
              margin: '10px',
              backgroundColor: '#dc8dee4a',
              backdropFilter: 'blur(5px)',
              padding: '8px',
              borderRadius: '15px',
              color: '#fff'
            }}>
              <small><b>By creating an account, you agree to the Terms of Service and Privacy Policy.</b></small>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  )
}
