import styles from './Button.module.scss';
import Spinner from '../Spinner/Spinner.js';
import clsx from 'clsx';

export default function Button ({ outline, dull, thin, inline, alt, danger, small, className, ...props}) {
  let buttonClassName = className ? 
  clsx([
    className,
    styles.button,
    danger && styles.danger,
    dull && styles.dull,
    small && styles.small,
    thin && styles.thin,
    inline && styles.inline,
    alt && styles.alt,
    outline && styles.outline,
  ])
  : clsx([
    styles.button,
    danger && styles.danger,
    dull && styles.dull,
    small && styles.small,
    thin && styles.thin,
    inline && styles.inline,
    alt && styles.alt,
    outline && styles.outline,
  ]);

  return (
    <button {...props} className={buttonClassName} disabled={props.loading || props.loading}>
      {props.loading ? <Spinner /> : props.children}
    </button>
  );
}
