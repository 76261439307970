export function containsNumber(str) {
  const regex = /\d/;
  return regex.test(str);
}

export function containsCapitalLetter(str) {
  const regex = /[A-Z]/;
  return regex.test(str);
}

export function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}