export const gender = ['female', 'male', 'futa', 'femboy'];
export const genderImages = {
  female: 'https://s3.amazonaws.com/mindscape.is/characters/51fc08a5-575f-4629-a164-b3b725c68490.png',
  male: 'https://s3.amazonaws.com/mindscape.is/characters/7a78e900-8568-4ff0-a21f-b32a712cacf7.png',
  futa: 'https://s3.amazonaws.com/mindscape.is/characters/5aa4f0c4-68a3-4962-8df7-8903c1e2e2b8.png',
  femboy: 'https://s3.amazonaws.com/mindscape.is/characters/b342397c-7868-4448-9d7c-ef41597c0620.png'
}
export const face = [
  "Default",
  "Orgasm Face",
  "Smug",
  "Blush",
  "Calm",
  "Smile",
  "Surprised",
  "Shocked",
  "Looking at Viewer",
  "Joyful"
];

export const age = ['18', '20s', '30s', '40s', '50s'];

export const ethnicity = [
  "European",
  "Indian",
  "Ebony",
  "Asian",
  "Latina",
  "Arabian"
];

export const hairColor = [
  "Multicolored",
  "Black",
  "White",
  "Brunette",
  "Purple",
  "Pink"
];

export const sfwPose = ['lying on bed', 'sitting on couch', 'reaching out to hug', 'portrait photo', 'sleeping next to you', 'boudoir pose', 'shopping', 'dancing', 'tennis', 'yoga'];
export const sfwPoseImages = {
  'lying on bed': 'https://s3.amazonaws.com/mindscape.is/characters/a0fcf349-d3f9-4085-a663-9e2cd5e25a7b.png',
  'sitting on couch': 'https://s3.amazonaws.com/mindscape.is/characters/93a5ac20-8f84-4b30-aa74-7942cfb1dbe6.png',
  'reaching out to hug': 'https://s3.amazonaws.com/mindscape.is/characters/d03e8c3e-3aba-4fea-8eb7-b749b9aa9150.png',
  'portrait photo': 'https://s3.amazonaws.com/mindscape.is/characters/bac2e946-cc5c-4c5a-aaad-4ad24eb90ad3.png',
  'sleeping next to you': 'https://s3.amazonaws.com/mindscape.is/characters/0afe6353-a1d8-4b28-b847-d56ef5aca489.png',
  'boudoir pose': 'https://s3.amazonaws.com/mindscape.is/characters/af435f47-a858-4d48-93b7-35763c60c7d2.png',
  shopping: 'https://s3.amazonaws.com/mindscape.is/characters/26ddf730-00a7-45ac-bbc6-414e54915015.png',
  dancing: 'https://s3.amazonaws.com/mindscape.is/characters/475dc7f1-c547-4cf9-83c4-a76d309fb4cd.png',
  tennis: 'https://s3.amazonaws.com/mindscape.is/characters/b16a606d-3a27-4005-8138-3d13853a1211.png',
  yoga: 'https://s3.amazonaws.com/mindscape.is/characters/e8ade2b0-d6cd-40b9-8943-15b6998e7a69.png'
};

export const nsfwPose = ['ass up looking back', 'masturbating on bed', 'downblouse', 'shibari', 'tied up on bed', 'legs up pussy showing', 'legs up anal sex', 'view looking up with pussy and breasts in view', 'riding a dildo', 'sucking cock', 'doggy style', 'doggy style anal', 'spread pussy sitting on bed', 'tit fuck', 'anal', 'missionary', 'missionary anal', 'breasts on glass', 'facial cumshot', 'belly cumshot', 'spread ass', 'cow girl riding cock', 'reverse cowgirl riding cock'];
export const nsfwPoseImages = {
  'ass up looking back': 'https://s3.amazonaws.com/mindscape.is/characters/db6d04dc-7b09-498a-955a-158b3d5c19b4.png',
  'masturbating on bed': 'https://s3.amazonaws.com/mindscape.is/characters/a51079eb-3587-445b-870d-5d78268de3bf.png',
  downblouse: 'https://s3.amazonaws.com/mindscape.is/characters/e7936224-d5d4-44ed-9e04-4e87952655a6.png',
  shibari: 'https://s3.amazonaws.com/mindscape.is/characters/a92f57c0-1474-43bb-ba9e-6f211b65d072.png',
  'tied up on bed': 'https://s3.amazonaws.com/mindscape.is/characters/c5b438a6-961c-4f88-aa0f-c19dad1467ba.png',
  'legs up pussy showing': 'https://s3.amazonaws.com/mindscape.is/characters/d59605de-45e3-4bb1-950f-a288ce9ac037.png',
  'legs up anal sex': 'https://s3.amazonaws.com/mindscape.is/characters/b671b92a-2033-46ca-ab08-e797eb5ca069.png',
  'view looking up with pussy and breasts in view': 'https://s3.amazonaws.com/mindscape.is/characters/7f5c6686-fbe4-4d25-963f-214bd19d430a.png',
  'riding a dildo': 'https://s3.amazonaws.com/mindscape.is/characters/786ed98a-e969-4a2c-b89e-53e987af5d13.png',
  'sucking cock': 'https://s3.amazonaws.com/mindscape.is/characters/5994613f-a623-426a-920c-00f3cf88af73.png',
  'doggy style': 'https://s3.amazonaws.com/mindscape.is/characters/76c96cbd-c953-40ab-a6b7-b2f15e476975.png',
  'doggy style anal': 'https://s3.amazonaws.com/mindscape.is/characters/d21bf004-cd85-4354-b25c-aa84d6a79c5f.png',
  'spread pussy sitting on bed': 'https://s3.amazonaws.com/mindscape.is/characters/556addc3-0bb3-4871-91e2-60fa697070fa.png',
  'tit fuck': 'https://s3.amazonaws.com/mindscape.is/characters/fd080918-66da-47c9-a5ce-f50d6d9096a7.png',
  anal: 'https://s3.amazonaws.com/mindscape.is/characters/8cc506c1-8a65-4e9f-9841-f6c525a316ed.png',
  missionary: 'https://s3.amazonaws.com/mindscape.is/characters/c740938d-6ffb-4869-ae61-1d17075ff451.png',
  'missionary anal': 'https://s3.amazonaws.com/mindscape.is/characters/6b70727c-1d77-4d9a-a90f-c513890b1cc2.png',
  'breasts on glass': 'https://s3.amazonaws.com/mindscape.is/characters/f592867c-51a0-49c6-bba9-629f538c6d96.png',
  'facial cumshot': 'https://s3.amazonaws.com/mindscape.is/characters/854ac82e-5560-41e3-9a65-d2df74fbc9c0.png',
  'belly cumshot': 'https://s3.amazonaws.com/mindscape.is/characters/580e0fd5-40be-4648-937e-37f3cb3da0a5.png',
  'spread ass': 'https://s3.amazonaws.com/mindscape.is/characters/14044b71-c775-4f2b-a5fa-f55312e6ca4a.png',
  'cow girl riding cock': 'https://s3.amazonaws.com/mindscape.is/characters/e410f8a2-ade1-434d-804d-068c378b5612.png',
  'reverse cowgirl riding cock': 'https://s3.amazonaws.com/mindscape.is/characters/4f3248aa-bca7-4e9b-b638-9f56f708d0cb.png'
}

export const bodyType = ['skinny', 'fit', 'chubby', 'fat', 'muscular'];
export const bodyTypeImages = {
  skinny: 'https://s3.amazonaws.com/mindscape.is/characters/39b05757-f98b-40be-b379-a0c7f175d4a6.png',
  fit: 'https://s3.amazonaws.com/mindscape.is/characters/2569edad-d871-45d6-85a3-f9751341f7a2.png',
  chubby: 'https://s3.amazonaws.com/mindscape.is/characters/8587805f-a97c-49c5-9398-05fc2a26f8ee.png',
  fat: 'https://s3.amazonaws.com/mindscape.is/characters/33559ca4-2482-4b9a-89a1-172ae9fd0b40.png',
  muscular: 'https://s3.amazonaws.com/mindscape.is/characters/a8769ca8-06e7-4f80-9201-97e64273a6c2.png'
}

export const style = ['anime', 'realistic'];
export const styleImages = {
  anime: 'https://s3.amazonaws.com/mindscape.is/characters/a18fd739-70ed-4dd8-8f9f-4df89e451d20.png',
  realistic: 'https://s3.amazonaws.com/mindscape.is/characters/76c96cbd-c953-40ab-a6b7-b2f15e476975.png'
};

export const breastSize = ['small', 'medium', 'large', 'sagging breast'];