import styles from './SecurityInput.module.scss';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';

export default function SecurityInput ({ label, id, error, tooltip, onChange, ...rest }) {
  const securityInputClassName = clsx([
    styles.securityInputWrapper,
    error && styles.error,
  ])
  const [value, setValue] = useState(['', '', '', '', '', '']);
  const input0Ref = useRef();
  const input1Ref = useRef();
  const input2Ref = useRef();
  const input3Ref = useRef();
  const input4Ref = useRef();
  const input5Ref = useRef();

  const handleInputChange = useCallback((e, index) => {
    const newValue = e.target.value;
    const newValues = [...value];

    newValues[index] = newValue;
    setValue(newValues);

    switch (index) {
      case 0:
        if (newValue.length === 1) input1Ref.current.focus();
        break;
      case 1:
        if (newValue.length === 1) input2Ref.current.focus();
        else if (newValue.length === 0) input0Ref.current.focus();
        break;
      case 2:
        if (newValue.length === 1) input3Ref.current.focus();
        else if (newValue.length === 0) input1Ref.current.focus();
        break;
      case 3:
        if (newValue.length === 1) input4Ref.current.focus();
        else if (newValue.length === 0) input2Ref.current.focus();
        break;
      case 4:
        if (newValue.length === 1) input5Ref.current.focus();
        else if (newValue.length === 0) input3Ref.current.focus();
        break;
      case 5:
        if (newValue.length === 0) input4Ref.current.focus();
        break;
      default:
        break;
    }
  }, [value, onChange]);

  useEffect(() => {
    onChange && onChange(value.join(''));
  }, [value, onChange]);

  return (
    <div>
      <div className={securityInputClassName}>
        <input
          ref={input0Ref}
          pattern="[0-9]{1}"
          type={'text'}
          maxLength={1}
          minLength={1}
          onChange={e => {
            handleInputChange(e, 0)
          }}
          onPaste={e => {
            let input = e.clipboardData.getData('Text');

            let newValues = [];
            let length = input.length;
            for(let x = 0; x < length; x += 1) {
              newValues.push(input.charAt(x));
            };

            console.log('Values -> ', newValues);

            setValue(newValues);
          }}
          value={value[0]}
        />
        <input
          ref={input1Ref}
          pattern="[0-9]{1}"
          type={'text'}
          maxLength={1}
          minLength={1}
          onChange={e => handleInputChange(e, 1)}
          value={value[1]}
        />
        <input
          ref={input2Ref}
          pattern="[0-9]{1}"
          type={'text'}
          maxLength={1}
          minLength={1}
          onChange={e => handleInputChange(e, 2)}
          value={value[2]}
        />
        <input
          ref={input3Ref}
          pattern="[0-9]{1}"
          type={'text'}
          maxLength={1}
          minLength={1}
          onChange={e => handleInputChange(e, 3)}
          value={value[3]}
        />
        <input
          ref={input4Ref}
          pattern="[0-9]{1}"
          type={'text'}
          maxLength={1}
          minLength={1}
          onChange={e => handleInputChange(e, 4)}
          value={value[4]}
        />
        <input
          ref={input5Ref}
          pattern="[0-9]{1}"
          type={'text'}
          maxLength={1}
          minLength={1}
          onChange={e => handleInputChange(e, 5)}
          value={value[5]}
        />
      </div>
      {error && <p className={styles.errorText}>{error}</p>}
    </div>
  );
}
