import styles from './Input.module.scss';
import clsx from 'clsx';

export default function Input({ label, id, error, tooltip, thin, textSmall, titleWhite, OnHover, ...rest }) {
  if (tooltip && !label) throw Error('Cannot use tooltip without label');

  return (
    <div className={clsx([
      styles.inputWrapper,
      textSmall && styles.textSmall,
      error && styles.inputHasError,
      thin && styles.thinput,
    ])}>
      {label && (
        <div className={styles.inputLabel}>
          <label style={titleWhite ? { color: '#fff' } : {}} htmlFor={id}>{label}</label>
          {tooltip && (
            <div className={styles.tooltipWrapper}>
              <span className={styles.tooltipIndicator}>i</span>
              <div className={styles.tooltipMessage}>{tooltip}</div>
            </div>
          )}
        </div>
      )}
      {rest.type == 'textarea' ? <textarea rows={"100"}  {...rest} id={id} autoComplete={'off'}/> : <input {...rest} id={id} autoComplete={'off'} /> }
      {error && <p className={styles.inputError}>{error}</p>}
    </div>
  );
}
