export default function updateImageUrl(url) {
  if (typeof url !== 'string') {
    return url;
  }

  const oldPrefix = 'https://mindscape.is.s3.amazonaws.com/';
  const newPrefix = 'https://s3.amazonaws.com/mindscape.is/';

  if (url.startsWith(oldPrefix)) {
    return url.replace(oldPrefix, newPrefix);
  }

  return url;
};
