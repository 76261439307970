import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';

export default function ComplaintPolicy() {
  const policySections = [
    {
      title: "Introduction",
      content: "At Mindscape, we prioritize a safe and compliant environment for all users. We strongly encourage users to report any content they believe might be illegal or violate our Terms of Use (TOU). Upon receiving a complaint, Mindscape commits to conducting a thorough review of the reported content within the timeframe specified in this Complaint Policy."
    },
    {
      title: "Potential Outcomes",
      content: "When you make a complaint, you can expect one of these potential outcomes: 1) Content Removal: If the reported content violates our TOU or applicable law, it will be promptly removed or modified. 2) Content Retention: If the content is deemed compliant, it will remain accessible. 3) Other Actions: Depending on the severity or nature of the violation, additional actions such as warnings, user account suspension, or termination may be taken."
    },
    {
      title: "Customer Support",
      content: "We have a dedicated support team to assist our users with any concerns or complaints. Our team is committed to providing prompt and effective assistance, managing all inquiries with professionalism, confidentiality, and impartiality."
    },
    {
      title: "Submitting a Complaint",
      content: "Users can file a complaint by contacting our customer support team through Support or directly report in the APP in the 'Contact' section."
    },
    {
      title: "Information to Include",
      content: "When submitting a complaint, please provide: 1) Your full name and e-mail; 2) Description of the complaint, including relevant details such as the date and time of the incident; 3) Any supporting documentation or screenshots, if applicable."
    },
    {
      title: "Acknowledgment and Resolution Timeline",
      content: "We will acknowledge receipt of your complaint within 24 hours via e-mail. We strive to address and resolve complaints within 7 (seven) business days from the date of receipt. During this time, we may contact you for additional information."
    },
    {
      title: "Investigation and Resolution",
      content: "We will conduct a thorough investigation into each complaint. Our goal is to provide a resolution within a reasonable timeframe. Users will be kept informed of the progress and expected resolution timeline."
    },
    {
      title: "Feedback and Follow-Up",
      content: "Once the complaint has been addressed, users will receive feedback regarding the outcome of the investigation and any actions taken. We may also seek user feedback on the resolution process."
    },
    {
      title: "Escalation",
      content: "If a user is dissatisfied with the resolution provided, they may request further escalation. The case will be reviewed by individuals or teams not initially involved in the complaint resolution process. The user will be notified of the outcome of the escalation within a reasonable timeframe."
    }
  ];

  return (
    <>
      <DashboardLayout title={'Complaint Policy'}>
        <div style={{
          maxWidth: '100%',
          margin: '0 auto',
          padding: '20px',
          fontFamily: 'Arial, sans-serif',
          backgroundColor: '#121212a0',
        }}>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            Last updated: {new Date().toLocaleDateString()}
          </p>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            This Complaint Policy outlines Mindscape's procedures for addressing user complaints 
            and ensuring a safe environment for all users of our platform.
          </p>
          {policySections.map((section, index) => (
            <div key={index} style={{
              marginBottom: '20px',
              padding: '15px',
              borderRadius: '5px'
            }}>
              <h2 style={{
                color: '#fff',
                fontSize: '20px',
                marginBottom: '10px',
                fontWeight: 'bold'
              }}>{section.title}</h2>
              <p style={{
                fontSize: '14px',
                lineHeight: '1.5',
                color: '#fff'
              }}>{section.content}</p>
            </div>
          ))}
          <p style={{
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '30px',
            color: '#fff'
          }}>
            Mindscape reserves the right to modify this Complaint Policy as needed and without prior notice 
            to ensure alignment with applicable laws and enhance the user experience.
          </p>
        </div>
      </DashboardLayout>
    </>
  )
}