import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';

export default function UnderagePolicy() {
  const policySections = [
    {
      title: "Introduction",
      content: "At Mindscape, we are committed to ensuring our Website, App(s), and Services are used responsibly and safely by users who meet legal age requirements. Our platform includes AI-generated adult content for registered users only, requiring users to be at least 18 years old or of legal age in their country of residence."
    },
    {
      title: "Age Verification",
      content: "When registering, users encounter an age gate designed to restrict entry to those of legal age. Users must confirm they are over 18 before accessing main content. A disclaimer emphasizes the age requirement and notes that providing false age information violates our Terms of Service. Mindscape is not responsible for user misrepresentations regarding age."
    },
    {
      title: "Content Responsibility",
      content: "Users bear sole responsibility for content generated by AI Companions, including text, voice messages, images, and videos. AI Companions evolve based on user interactions and parameters. Users must ensure their interactions comply with laws, regulations, and our policies, particularly the Blocked Content Policy. Any illegal, unethical, or harmful activities are strictly prohibited."
    },
    {
      title: "Prohibited Content",
      content: "We explicitly prohibit content that exploits or endangers minors, including child pornography, sexual exploitation, or any form of harm or harassment towards minors. Generating AI content resembling or exploiting minors' likeness is strictly forbidden."
    },
    {
      title: "Content Moderation",
      content: "While user-AI conversations are generally confidential, we employ a proprietary content moderation filter to ensure compliance with our policies. If the filter detects policy violations, we may manually review flagged content and take appropriate action, including account termination."
    },
    {
      title: "Content Removal",
      content: "Any user content that we believe, in our sole discretion, violates these provisions will be promptly removed."
    },
    {
      title: "Reporting Violations",
      content: "If you notice any violations of this Underage Policy or have questions, please contact us at Support or use the 'Contact' section in our App(s)."
    },
    {
      title: "Termination",
      content: "We reserve the right to suspend or terminate the usage of our Website, App(s), or Services for individuals involved in suspected infringements of this Underage Policy."
    }
  ];

  return (
    <>
      <DashboardLayout title={'Underage Policy'}>
        <div style={{
          maxWidth: '100%',
          margin: '0 auto',
          padding: '20px',
          fontFamily: 'Arial, sans-serif',
          backgroundColor: '#121212a0',
        }}>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            Last updated: {new Date().toLocaleDateString()}
          </p>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            This Underage Policy outlines Mindscape's commitment to protecting minors and ensuring 
            our services are accessed only by users of legal age. We take this responsibility seriously 
            and have implemented various measures to uphold this policy.
          </p>
          {policySections.map((section, index) => (
            <div key={index} style={{
              marginBottom: '20px',
              padding: '15px',
              borderRadius: '5px'
            }}>
              <h2 style={{
                color: '#fff',
                fontSize: '20px',
                marginBottom: '10px',
                fontWeight: 'bold'
              }}>{section.title}</h2>
              <p style={{
                fontSize: '14px',
                lineHeight: '1.5',
                color: '#fff'
              }}>{section.content}</p>
            </div>
          ))}
          <p style={{
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '30px',
            color: '#fff'
          }}>
            By using Mindscape, you agree to comply with this Underage Policy and affirm that you meet the minimum age requirements.
          </p>
        </div>
      </DashboardLayout>
    </>
  )
}