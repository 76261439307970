export default function LogoutIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9_45)">
        <path d="M8.625 0C9.05647 0 9.40625 0.349777 9.40625 0.78125C9.40625 1.21272 9.05647 1.5625 8.625 1.5625H1.5625V14.4375H8.625C9.05647 14.4375 9.40625 14.7873 9.40625 15.2188C9.40625 15.6502 9.05647 16 8.625 16H0.78125C0.349778 16 0 15.6502 0 15.2188V0.78125C0 0.349777 0.349778 0 0.78125 0H8.625ZM11.8038 3.49313L15.7706 7.44656C15.9178 7.59316 16.0005 7.7923 16.0005 8C16.0005 8.2077 15.9178 8.40684 15.7706 8.55344L11.8038 12.5069C11.6576 12.6531 11.4593 12.7352 11.2525 12.735C10.9362 12.735 10.6511 12.5444 10.5303 12.2521C10.4095 11.9598 10.4769 11.6235 10.7009 11.4003L13.328 8.781L5.84375 8.78125C5.41228 8.78125 5.0625 8.43147 5.0625 8C5.0625 7.56853 5.41228 7.21875 5.84375 7.21875L13.327 7.218L10.7009 4.59969C10.4975 4.40387 10.4156 4.11356 10.4866 3.8403C10.5577 3.56705 10.7706 3.35339 11.0436 3.28142C11.3167 3.20946 11.6073 3.2904 11.8038 3.49313Z" fill="#fff" fillOpacity="0.6"/>
      </g>
      <defs>
        <clipPath id="clip0_9_45">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}
