import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/Personas.module.scss';
import axiosRequest from '../utils/request.js';

export default function Personas() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.loggedIn);
  const user = useSelector(state => state.userData);
  
  const [personas, setPersonas] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingPersona, setEditingPersona] = useState(null);
  const [newPersona, setNewPersona] = useState({ name: '', description: '', gender: '', default: false });

  const openModal = (persona = null) => {
    if (persona) {
      setEditingPersona(persona);
      setNewPersona({ ...persona });
    } else {
      setEditingPersona(null);
      setNewPersona({ name: '', description: '', gender: '', default: false });
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditingPersona(null);
    setNewPersona({ name: '', description: '', gender: '', default: false });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewPersona(prev => ({ 
      ...prev, 
      [name]: type === 'checkbox' ? checked : value 
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let updatedPersonas;
    let newId;
    if (editingPersona) {
      updatedPersonas = personas.map(p => p.id === editingPersona.id ? { ...p, ...newPersona } : p);
    } else {
      newId = Date.now().toString();
      updatedPersonas = [...personas, { ...newPersona, id: newId }];
    }
  
    if (newPersona.default) {
      updatedPersonas = updatedPersonas.map(p => ({...p, default: p.id === (editingPersona?.id || newId)}));
    }
  
    let token = localStorage.getItem('jwt');

    const response = await axiosRequest('POST', 'personas/update', { personas: updatedPersonas }, token);
  
    if (response && response.status === 200) {
      setPersonas(updatedPersonas);
      toast.success(editingPersona ? 'Persona updated successfully!' : 'New persona created successfully!');
      closeModal();
    } else {
      toast.error('Failed to update personas. Please try again.');
    }
  };

  const toggleDefault = async (id) => {
    const updatedPersonas = personas.map(p => ({
      ...p,
      default: p.id === id
    }));

    let token = localStorage.getItem('jwt');

    const response = await axiosRequest('POST', 'personas/update', { personas: updatedPersonas }, token);

    if (response && response.status === 200) {
      setPersonas(updatedPersonas);
      toast.success('Default persona updated successfully!');
    } else {
      toast.error('Failed to update default persona. Please try again.');
    }
  };

  useEffect(() => {
    if(user && user.personas) {
      setPersonas(user.personas);
    }
  }, [user]);

  return (
    <>
      {!loggedIn ? (
        <></>
      ) : (
        <DashboardLayout title={'Personas'}>
          <div className={styles.personasContainer}>
            <div className={styles.personasList}>
              <div className={styles.createPersona} onClick={() => openModal()}>
                <span style={{ fontSize: '38px' }}>+</span>
                <p>Create New Persona</p>
              </div>
              {personas.map(persona => (
                <div key={persona.id} className={styles.personaCard}>
                  <div onClick={() => openModal(persona)}>
                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h3>{persona.name}</h3>
                      {
                        persona.default ? <small><b>Default</b></small> : ''
                      }
                    </span>
                    <p>{persona.description || 'No description'}</p>
                  </div>
                  <label className={styles.switch}>
                    <input
                      type="checkbox"
                      checked={persona.default}
                      onChange={() => toggleDefault(persona.id)}
                    />
                    <span className={styles.slider}></span>
                  </label>
                </div>
              ))}
            </div>
          </div>

          {isModalOpen && (
            <div className={styles.modal}>
              <div className={styles.modalContent}>
                <h2>{editingPersona ? 'Update Persona' : 'Create New Persona'}</h2>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    value={newPersona.name}
                    onChange={handleInputChange}
                    placeholder="Name"
                    required
                  />
                  <textarea
                    name="description"
                    value={newPersona.description}
                    onChange={handleInputChange}
                    placeholder="Description"
                  />
                  <select
                    name="gender"
                    value={newPersona.gender}
                    onChange={handleInputChange}
                  >
                    <option value="">Choose your Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="trans">Trans</option>
                    <option value="other">Other</option>
                  </select>
                  <label>
                    <input
                      type="checkbox"
                      name="default"
                      checked={newPersona.default}
                      onChange={handleInputChange}
                    />
                    Set as default
                  </label>
                  <div className={styles.modalActions}>
                    <button type="button" onClick={closeModal}>Cancel</button>
                    <button type="submit">Save</button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </DashboardLayout>
      )}
    </>
  );
}