const initialState = {
  userData: null,
  creators: [],
  rememberMe: { enabled: false, email: undefined },
  loggedIn: false,
  nsfw: false
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_USER_DATA':
      return {
        ...state,
        userData: action.data,
      }
    case 'UPDATE_CREATORS':
      return {
        ...state,
        creators: action.data
      }
    case 'UPDATE_LOGGED_IN':
      return {
        ...state,
        loggedIn: action.data
      }
    case 'UPDATE_REMEMBER_ME':
      return {
        ...state,
        rememberMe: action.data
      }
    case 'UPDATE_NSFW':
      return {
        ...state,
        nsfw: action.data
      }
    default:
      return {
        ...state
      }
  }
};

export default Reducer;