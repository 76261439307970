import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';

export default function BlockedContentPolicy() {
  const policySections = [
    {
      title: "Introduction",
      content: "At Mindscape, we are committed to providing a secure, respectful, and lawful online environment for all users. Although users cannot upload content directly, they can chat and initiate AI-generated content creation. This content is accessible only to the specific user in their private space and is not publicly accessible. However, certain categories of content are strictly forbidden on our platform, as outlined in this Blocked Content Policy."
    },
    {
      title: "Prohibited Content",
      content: "This policy applies to all content, including text, images, videos, and any other AI-generated content created on our platform. Prohibited content includes: 1) Illegal content, 2) Hate speech and discrimination, 3) Violence and harm, 4) Child exploitation, 5) Content resembling minors, 6) Infringement on privacy and copyright, 7) Impersonation and celebrity content."
    },
    {
      title: "Content Responsibility",
      content: "As a user, you are solely responsible for the output generated by the AI Companions through text messages, voice messages, images, and videos. You must ensure that your interactions comply with applicable laws, regulations, our Terms of Use, Community Guidelines, and this Blocked Content Policy. Mindscape does not control or endorse the content generated by the AI Companions."
    },
    {
      title: "Content Moderation",
      content: "While conversations between users and AI Companions are generally confidential, we have implemented a content moderation filter based on our proprietary LLM technology. If the filter detects content that violates our policies, we reserve the right to manually review the flagged content and take appropriate action, which may include terminating the user's account."
    },
    {
      title: "Content Removal and Account Suspension",
      content: "Violations of this Blocked Content Policy may result in content removal, account suspension, or legal action as appropriate."
    },
    {
      title: "Reporting Violations",
      content: "If you notice any violation of our policies or have questions about our Blocked Content Policy, please contact us at Support or use the 'Contact' section within our App(s)."
    },
    {
      title: "Termination",
      content: "We reserve the right to suspend or terminate the use of our platform by anyone engaged in suspected infringement of this policy."
    }
  ];

  return (
    <>
      <DashboardLayout title={'Blocked Content Policy'}>
        <div style={{
          maxWidth: '100%',
          margin: '0 auto',
          padding: '20px',
          fontFamily: 'Arial, sans-serif',
          backgroundColor: '#121212a0',
        }}>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            Last updated: {new Date().toLocaleDateString()}
          </p>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            This Blocked Content Policy outlines the types of content that are prohibited on Mindscape's platform. 
            We are committed to maintaining a safe and respectful environment for all our users.
          </p>
          {policySections.map((section, index) => (
            <div key={index} style={{
              marginBottom: '20px',
              padding: '15px',
              borderRadius: '5px'
            }}>
              <h2 style={{
                color: '#fff',
                fontSize: '20px',
                marginBottom: '10px',
                fontWeight: 'bold'
              }}>{section.title}</h2>
              <p style={{
                fontSize: '14px',
                lineHeight: '1.5',
                color: '#fff'
              }}>{section.content}</p>
            </div>
          ))}
          <p style={{
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '30px',
            color: '#fff'
          }}>
            By using Mindscape, you agree to comply with this Blocked Content Policy.
          </p>
        </div>
      </DashboardLayout>
    </>
  )
}