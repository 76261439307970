import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';

export default function PrivacyPolicy() {
  const privacySections = [
    {
      title: "Introduction",
      content: "Welcome to Mindscape! This Privacy Policy provides detailed information on how we collect, use, and protect your personal information when you use our website (https://www.mindscape.is), mobile applications, and services. Please read this policy carefully. By accessing or using Mindscape, you agree to this Privacy Policy. We may update this policy periodically, so please review it regularly."
    },
    {
      title: "GDPR Compliance",
      content: "For European Union residents, we comply with the General Data Protection Regulation (GDPR). This includes rights to data transparency, access, rectification, erasure, processing restriction, and data portability."
    },
    {
      title: "Definitions",
      content: "Content: Information you provide for registration and uploads, including Personal Data and AI Companion discussions. Consent: Your agreement to data processing. Personal Data: Information relating to you as an identified person. Processing: Operations performed on Personal Data. Services: Steps enabling AI Companion chats. User: Person or company using Mindscape. Website: https://www.mindscape.is."
    },
    {
      title: "Data Processing Purposes",
      content: "We collect and process data to: Analyze website behavior, perform and improve our services, enhance communications, improve security, and evaluate marketing effectiveness. When you register, we use your data to set up your account, adapt services, and develop new tools."
    },
    {
      title: "Personal Data Collection",
      content: "For visitors, we collect anonymous metadata through cookies. For users, we collect data from registration, account content, communications, automated technologies, and third parties. This may include names, birth dates, usage information, and browser data."
    },
    {
      title: "Marketing",
      content: "We may send you marketing materials about our services. We'll get your express consent before sharing your data with third parties for marketing. You can opt out of marketing messages at any time."
    },
    {
      title: "Cookies",
      content: "You can set your browser to refuse cookies, but this may affect website functionality. We use cookies for analytics, preferences, and marketing."
    },
    {
      title: "Sharing Your Information",
      content: "We may share your data with service providers, professional advisers, legal authorities, and employees. We won't sell or trade your information without consent."
    },
    {
      title: "Sensitive Information",
      content: "We don't request sensitive personal data (e.g., race, political opinions, religious beliefs). If you provide such information voluntarily, you understand the implications."
    },
    {
      title: "Data Controller",
      content: "Mindscape is the data controller. For questions about this policy or to exercise your rights, contact our appointed Controller at Support."
    },
    {
      title: "Data Retention",
      content: "We retain your data for as long as your account exists or as necessary for our services. After account closure, we typically retain data for 6 years."
    },
    {
      title: "Minors' Information",
      content: "Our services are for adults (18+). We don't knowingly collect data from minors. If we learn we've collected such data, we'll delete it promptly."
    },
    {
      title: "Third-Party Links",
      content: "Our website may contain links to third-party sites. We're not responsible for their privacy practices. Read their privacy policies when visiting these sites."
    },
    {
      title: "Company Merger",
      content: "If Mindscape merges or is acquired, your data may be transferred but will remain subject to this policy."
    },
    {
      title: "Your Rights",
      content: "You have rights to access, rectify, restrict processing, withdraw consent, and request erasure of your data. Contact our Controller to exercise these rights."
    },
    {
      title: "Data Security",
      content: "We implement appropriate security measures to protect your data from unauthorized access, alteration, or disclosure. We have procedures to handle potential data breaches."
    },
    {
      title: "Policy Changes",
      content: "We may revise this policy periodically. Significant changes will be notified to you. Continued use of our services after changes indicates your acceptance of the revised policy."
    }
  ];

  return (
    <>
      <DashboardLayout title={'Privacy Policy'}>
        <div style={{
          maxWidth: '100%',
          margin: '0 auto',
          padding: '20px',
          fontFamily: 'Arial, sans-serif',
          backgroundColor: '#121212a0',
        }}>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            Last updated: {new Date().toLocaleDateString()}
          </p>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            This Privacy Policy outlines how Mindscape collects, uses, and protects your personal information. 
            We are committed to safeguarding your privacy and ensuring transparency in our data practices.
          </p>
          {privacySections.map((section, index) => (
            <div key={index} style={{
              marginBottom: '20px',
              padding: '15px',
              borderRadius: '5px'
            }}>
              <h2 style={{
                color: '#fff',
                fontSize: '20px',
                marginBottom: '10px',
                fontWeight: 'bold'
              }}>{section.title}</h2>
              <p style={{
                fontSize: '14px',
                lineHeight: '1.5',
                color: '#fff'
              }}>{section.content}</p>
            </div>
          ))}
          <p style={{
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '30px',
            color: '#fff'
          }}>
            By using Mindscape, you agree to the practices described in this Privacy Policy.
          </p>
        </div>
      </DashboardLayout>
    </>
  )
}