import { useEffect, useState } from 'react';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/Favorites.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import axiosRequest from '../utils/request';
import { useNavigate } from 'react-router-dom';
import CustomDropdown from '../components/Dropdown';

import formatNumber from '../utils/formatNumber';
import AgeConfirmModal from '../components/AgeConfirmModal';

import updateImageUrl from '../utils/updateImageUrl';

const NSFWOverlay = ({ onShow, likes }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.content}>
        <p className={styles.ratingText}>This Character is rated XXX</p>
        <button className={styles.showButton} onClick={onShow}>Show</button>
      </div>
      <div className={styles.likes}>
        <span>♥</span> {likes}
      </div>
    </div>
  );
};

const CharacterCardSkeleton = () => (
  <div className={`${styles.characterCard} ${styles.skeleton}`}>
    <div className={styles.imageContainer}>
      <div className={styles.skeletonImage}></div>
      <div className={`${styles.likes} ${styles.skeletonText}`}></div>
    </div>
    <div className={`${styles.skeletonText} ${styles.skeletonTitle}`}></div>
    <div className={`${styles.skeletonText} ${styles.skeletonDescription}`}></div>
    <div className={styles.cardFooter}>
      <div className={`${styles.skeletonText} ${styles.skeletonTag}`}></div>
      <div className={`${styles.skeletonText} ${styles.skeletonMessages}`}></div>
    </div>
  </div>
);

export default function ForYou() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [sortBy, setSortBy] = useState('Most Liked');
  const sortOptions = ['Most Liked', 'Popular', 'Trending', 'Recent Hits', 'New'];

  const user = useSelector(state => state.userData);

  useEffect(() => {
    setCharacters([]);
    setPage(1);
    setHasMore(true);
    fetchCharacters(1);
  }, [sortBy]);

  const fetchCharacters = async (pageToFetch) => {
    if (loading) return;

    try {
      setLoading(true);
      const startTime = Date.now();

      const jwt = localStorage.getItem('jwt');

      let sortValue;
      switch (sortBy) {
        case 'Most Liked':
          sortValue = 'mostLiked';
          break;
        case 'Popular':
          sortValue = 'popular';
          break;
        case 'New':
          sortValue = 'new';
          break;
        default:
          sortValue = 'mostLiked';
      }
      const params = new URLSearchParams({
        page: pageToFetch.toString(),
        limit: '20',
        sort: sortValue
      });

      const endpoint = `characters/foryou?${params.toString()}`;

      const response = await axiosRequest('get', endpoint, null, jwt);

      if (response.data.success) {
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime < 1000) {
          await new Promise(resolve => setTimeout(resolve, 1000 - elapsedTime));
        }

        setCharacters(prevCharacters => [...prevCharacters, ...response.data.characters]);
        setHasMore(response.data.hasMore);
        setPage(pageToFetch);
      } else {
        setError('Failed to fetch recommended characters');
      }
    } catch (error) {
      console.log('Error fetching recommended characters:', error);
      setError('An error occurred while fetching recommended characters');
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (!loading && hasMore) {
      fetchCharacters(page + 1);
    }
  };

  const renderCharacterCards = () => {
    if (loading && characters.length === 0) {
      return Array(8).fill().map((_, index) => (
        <CharacterCardSkeleton key={`skeleton-${index}`} />
      ));
    }

    return characters.map((character, index) => (
      <div
        key={index}
        className={styles.characterCard}
        onClick={(e) => {
          e.preventDefault();
          
          if (character.nsfw && !nsfwEnabled) return;

          navigate(`/character/${character['_id']}`, { state: character });
        }}
      >
        <div className={styles.imageContainer}>
          <img src={updateImageUrl(character.imageUrl) || "/placeholder.avif"} alt={character.name} />
          {character.nsfw && !nsfwEnabled && (
            <NSFWOverlay onShow={handleShowNSFW} likes={character.likes} />
          )}
          <div className={styles.likes}>♥ {character.likes}</div>
          {character.deluxe && <div className={styles.deluxeTag}>Deluxe</div>}
        </div>
        <h3>{character.name}</h3>
        <p>{character.description.slice(0, 150)}{character.description.length > 150 ? '...' : ''}</p>
        <div className={styles.cardFooter}>
          {character.nsfw && <span className={styles.nsfwTag}>NSFW</span>}
          <span className={styles.messages}>{(formatNumber(character.messages) || 0).toLocaleString()} 💬</span>
        </div>
      </div>
    ));
  };

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
  };

  const nsfw = useSelector(state => state.nsfw);
  const [nsfwEnabled, setNSFW] = useState(nsfw);
  const [showAgeModal, setShowAgeModal] = useState(false);

  const handleShowNSFW = () => {
    setShowAgeModal(true);
  };

  const handleConfirmAge = () => {
    dispatch({
      type: 'UPDATE_NSFW',
      data: true
    });
    setNSFW(true);
    setShowAgeModal(false);
  };

  useEffect(() => {
    setNSFW(nsfw);
  }, [nsfw]);

  return (
    <>
      {<DashboardLayout
        title="Recommended for you"
        subtitle=""
      >
        <div className={styles.container}>
          <div className={styles.filterRow}>
            <CustomDropdown
              options={sortOptions}
              value={sortBy}
              onChange={handleSortChange}
              alt={true}
            />
            <span 
              className={styles.discoverNew}
              onClick={e => {
                e.preventDefault();
                navigate('/search');
              }}
            >
              ← discover new characters
            </span>
          </div>
          <div className={styles.characterGrid}>
            {renderCharacterCards()}
          </div>
          {hasMore && (
            <button onClick={loadMore} className={styles.loadMoreButton} disabled={loading}>
              {loading ? 'Loading...' : 'Load More'}
            </button>
          )}
          {error && <div className={styles.error}>{error}</div>}
        </div>
      </DashboardLayout>}
      {
        <AgeConfirmModal
          isOpen={showAgeModal}
          onClose={() => setShowAgeModal(false)}
          onConfirm={handleConfirmAge}
        />
      }
    </>
  );
}