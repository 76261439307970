import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/DynamicCategories.module.scss';
import axiosRequest from '../utils/request';
import CustomDropdown from '../components/Dropdown';

import { useSelector, useDispatch } from 'react-redux';
import formatNumber from '../utils/formatNumber';
import AgeConfirmModal from '../components/AgeConfirmModal';

import updateImageUrl from '../utils/updateImageUrl';

const NSFWOverlay = ({ onShow, likes }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.content}>
        <p className={styles.ratingText}>This Character is rated XXX</p>
        <button className={styles.showButton} onClick={onShow}>Show</button>
      </div>
      <div className={styles.likes}>
        <span>♥</span> {likes}
      </div>
    </div>
  );
};

// Skeleton loader component
const SkeletonLoader = () => (
  <div className={styles.skeletonCharacterCard}>
    <div className={styles.skeletonImageContainer}></div>
    <div className={styles.skeletonContent}>
      <div className={styles.skeletonTitle}></div>
      <div className={styles.skeletonDescription}></div>
      <div className={styles.skeletonFooter}></div>
    </div>
  </div>
);

export default function Following() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(true);
  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortBy, setSortBy] = useState('Popular');
  const sortOptions = ['Popular', 'Trending', 'Recent Hits', 'Most Liked', 'New'];

  useEffect(() => {
    fetchCharacters();
  }, [page, sortBy]);

  const nsfw = useSelector(state => state.nsfw);
  const [nsfwEnabled, setNSFW] = useState(nsfw);
  const [showAgeModal, setShowAgeModal] = useState(false);

  const handleShowNSFW = () => {
    setShowAgeModal(true);
  };

  const handleConfirmAge = () => {
    dispatch({
      type: 'UPDATE_NSFW',
      data: true
    });
    setNSFW(true);
    setShowAgeModal(false);
  };

  useEffect(() => {
    setNSFW(nsfw);
  }, [nsfw]);

  const fetchCharacters = async () => {
    setLoading(true);
    const startTime = Date.now();
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await axiosRequest('get', `characters/random-from-followed?page=${page}&limit=20&sort=${sortBy.toLowerCase().replace(' ', '-')}`, null, jwt);

      const loadTime = Date.now() - startTime;
      if (loadTime < 500) {
        await new Promise(resolve => setTimeout(resolve, 500 - loadTime));
      }

      setCharacters(prevCharacters => page === 1 ? response.data.characters : [...prevCharacters, ...response.data.characters]);
      setHasMore(response.data.hasNextPage);
    } catch (err) {
      console.error('Error fetching characters:', err);
      setError('Failed to load characters. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (!loading && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
    setPage(1);
    setCharacters([]);
    setHasMore(true);
  };

  if (!loggedIn) {
    return null;
  }

  return (
    <>
      {<DashboardLayout
        title={`Your Following Feed`}
        subtitle={`Check out all the cool characters from the creators you follow! They're right here for you to enjoy.`}
      >
        <div className={styles.container}>
          <div className={styles.filterRow}>
            <CustomDropdown
              options={sortOptions}
              value={sortBy}
              onChange={handleSortChange}
              alt={true}
            />
            <span
              className={styles.discoverNew}
              onClick={e => {
                e.preventDefault();
                navigate('/search');
              }}
            >
              ← discover new characters
            </span>
          </div>
          <div className={styles.characterGrid}>
            {characters.map((character) => (
              <div
                key={character._id}
                className={styles.characterCard}
                onClick={(e) => {
                  e.preventDefault();
                  
                  if (character.nsfw && !nsfwEnabled) return;
        
                  navigate(`/character/${character['_id']}`, { state: character });
                }}
              >
                <div className={styles.imageContainer}>
                  <img src={updateImageUrl(character.imageUrl) || "/placeholder.avif"} alt={character.name} />
                  {character.nsfw && !nsfwEnabled && (
                    <NSFWOverlay onShow={handleShowNSFW} likes={character.likes} />
                  )}
                  <div className={styles.likes}>♥ {character.likes}</div>
                  {character.nsfw && <div className={styles.nsfwTag}>NSFW</div>}
                </div>
                <h3>{character.name}</h3>
                <p>{character.description.slice(0, 150)}{character.description.length > 150 ? '...' : ''}</p>
                <div className={styles.cardFooter}>
                  <span className={styles.messages}>{(formatNumber(character.messages) || 0).toLocaleString()} 💬</span>
                </div>
              </div>
            ))}
          </div>
          {loading && (
            <div className={styles.skeletonCharacterGrid}>
              {Array(8).fill().map((_, index) => <SkeletonLoader key={`skeleton-${index}`} />)}
            </div>
          )}
          {error && <div className={styles.error}>{error}</div>}
          {!loading && hasMore && (
            <button className={styles.loadMoreButton} onClick={loadMore}>Load More</button>
          )}
          {!loading && !hasMore && characters.length > 0 && (
            <div className={styles.endMessage}>You've reached the end of your feed!</div>
          )}
        </div>
      </DashboardLayout>
      }
      {
        <AgeConfirmModal
          isOpen={showAgeModal}
          onClose={() => setShowAgeModal(false)}
          onConfirm={handleConfirmAge}
        />
      }
    </>
  );
}