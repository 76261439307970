import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';

import Link from '../../components/Link/Link.js';
import Input from '../../components/Input/Input.js';
import Title from '../../components/Title/Title.js';
import styles from "../../assets/pages/ResetPassword.module.scss";
import Button from '../../components/Button/Button.js';

import axiosRequest from '../../utils/request.js';

import { toast } from 'react-toastify';

export default function ForgotPassowrd() {
  const navigate = useNavigate();
  const [sent, setSent] = useState(false);

  const [email, setEmail] = useState();

  async function handleForgot() {
    try {
      let hasError = false;

      if (email == undefined) {
        hasError = true;
      };

      if (hasError) {
        toast.error('Must fill out all fields!');
        return;
      };

      const result = await axiosRequest('post', 'forgot-password', {email});

      if (result.data.success) {
        setSent(true);
        return;
      } else {
        toast.error(result.data.msg);
        return;
      }
    } catch (e) {
      toast.error('Unknown error submitting forgot request.');
      return;
    }
  };

  return (
    <>
      {
        <DashboardLayout
          title={'Forgot Password'}

        >
          <div className={styles.resetPage}>
            <div className={styles.container}>
              {!sent && (
                <>
                  <Title>Forgot Password</Title>
                  <div className={styles.elementWrapper}>
                    <Input onChange={e => setEmail(e.target.value)} label={"E-Mail Address"} id={'email'} type={'email'} />
                  </div>
                  <div className={styles.elementWrapper}>
                    <Button onClick={handleForgot}>Submit</Button>
                  </div>
                  <p className={styles.centered}>Changed your mind? <Link style={{ color: '#fff' }} to={'/login'}>Return to Login</Link></p>
                </>
              )}
              {sent && (
                <>
                  <Title>Reset Link Sent</Title>
                  <p className={styles.subtitle}>Check your email to reset your password.</p>
                  <div className={styles.elementWrapper}>
                    <Button onClick={() => navigate('/login')}>Return to Login</Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </DashboardLayout>
      }
    </>
  )
}