import styles from './Spinner.module.scss';

export default function Spinner () {
  return (
    <div className={styles.spinner}>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28 16C28 23.732 21.732 30 14 30C6.26801 30 0 23.732 0 16C0 8.26801 6.26801 2 14 2C21.732 2 28 8.26801 28 16ZM3.10554 16C3.10554 22.0168 7.98315 26.8945 14 26.8945C20.0168 26.8945 24.8945 22.0168 24.8945 16C24.8945 9.98315 20.0168 5.10554 14 5.10554C7.98315 5.10554 3.10554 9.98315 3.10554 16Z" fill="#DD8DEE" fillOpacity="0.05"/>
        <g filter="url(#filter0_d_13_108)">
          <path d="M26.4472 16C27.3048 16 28.0089 15.3027 27.914 14.4504C27.7685 13.1439 27.4394 11.8617 26.9343 10.6424C26.2307 8.94387 25.1995 7.40053 23.8995 6.1005C22.5995 4.80048 21.0561 3.76925 19.3576 3.06569C18.1383 2.56064 16.8561 2.23153 15.5496 2.08602C14.6973 1.9911 14 2.6952 14 3.55277V3.55277C14 4.41034 14.6987 5.09419 15.5475 5.21601C16.446 5.34494 17.3268 5.58593 18.1691 5.93483C19.4909 6.48233 20.6919 7.28481 21.7035 8.29645C22.7152 9.3081 23.5177 10.5091 24.0652 11.8309C24.4141 12.6732 24.6551 13.554 24.784 14.4525C24.9058 15.3013 25.5897 16 26.4472 16V16Z" fill="#DD8DEE"/>
        </g>
        <defs>
          <filter id="filter0_d_13_108" x="12" y="0.0774231" width="17.9225" height="17.9226" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="1"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.866667 0 0 0 0 0.552941 0 0 0 0 0.933333 0 0 0 0.42 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13_108"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13_108" result="shape"/>
          </filter>
        </defs>
      </svg>
    </div>
  );
}
