import { useState, useEffect } from 'react';
import styles from '../assets/pages/TopCreators.module.scss';
import { useNavigate } from 'react-router-dom';

import DashboardLayout from '../components/DashboardLayout/DashboardLayout';

let topCreators = [
  { username: "nsfwBabe", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/1.webp" },
  { username: "fondlercreator", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/10.webp" },
  { username: "nexubundas9", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/11.jpg" },
  { username: "lovaofpixls", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/12.jpeg" },
  { username: "lunaWave", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/13.jpg" },
  { username: "ghostedMod", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/15.jpg" },
  { username: "echo.77", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/16.webp" },
  { username: "neonPulse", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/17.jpg" },
  { username: "riftWalker", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/18.jpg" },
  { username: "cryptic_8", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/19.avif" },
  { username: "frostbyte", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/2.avif" },
  { username: "zenith.23", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/20.avif" },
  { username: "jake.zen", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/21.webp" },
  { username: "mystic_13", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/22.jpeg" },
  { username: "nova.9", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/23.jpeg" },
  { username: "byteKnight", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/24.jpeg" },
  { username: "glitch.99", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/25.jpeg" },
  { username: "phantomX", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/3.webp" },
  { username: "starlight.7", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/4.avif" },
  { username: "obsidian3", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/5.webp" },
  { username: "hyperFlux", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/6.jpeg" },
  { username: "quantum.2", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/7.jpeg" },
  { username: "leo.rift", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/8.jpeg" },
  { username: "sara.nova", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/9.jpeg" },
  { username: "emily.wander", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/9.jpeg" }
];

const mockData = {
  mostEngaging: topCreators.map((element, i) => ({
    id: i + 1,
    name: `${element.username}`,
    followers: Math.floor(Math.random() * 10000),
    characters: Math.floor(Math.random() * 1000),
    totalScores: (Math.random() * 20).toFixed(1) + 'M',
    pfp: element.pfp
  })),
  trendingCreators: topCreators.map((element, i) => ({
    id: i + 1,
    name: `${element.username}`,
    followers: Math.floor(Math.random() * 10000),
    characters: Math.floor(Math.random() * 1000),
    totalScores: (Math.random() * 20).toFixed(1) + 'M',
    pfp: element.pfp
  })),
  mostFollowers: topCreators.map((element, i) => ({
    id: i + 1,
    name: `${element.username}`,
    followers: Math.floor(Math.random() * 10000),
    characters: Math.floor(Math.random() * 1000),
    totalScores: (Math.random() * 20).toFixed(1) + 'M',
    pfp: element.pfp
  })),
};

export default function TopCreators() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('mostEngaging');
  const [creators, setCreators] = useState([]);

  useEffect(() => {
    setCreators(mockData[activeTab]);
  }, [activeTab]);

  const tabs = [
    { id: 'mostEngaging', label: 'Most Engaging' },
    { id: 'trendingCreators', label: 'Trending Creators' },
    { id: 'mostFollowers', label: 'Most Followers' },
  ];


  return (
    <DashboardLayout title="Top Creators">
      <div className={styles.topCreatorsContainer}>
        <div className={styles.tabs}>
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`${styles.tab} ${activeTab === tab.id ? styles.activeTab : ''}`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        
        <div className={styles.podium}>
          {[0, 1, 2].map((index) => (
            <div key={index} className={`${styles.podiumItem} ${styles[`place${index + 1}`]}`}>
              <div 
                className={styles.creatorInfo}
                onClick={e => {
                  e.preventDefault();
                  navigate(`/creator/${creators[index]?.name}`)
                }}
              >
                <div className={styles.avatar}>
                  <img src={`${creators[index]?.pfp}`} alt={creators[index]?.name} />
                  {index === 0 && <div className={styles.crown}>👑</div>}
                </div>
                <span className={styles.creatorName}>@{creators[index]?.name}</span>
              </div>
              <div className={styles.pedestal}>
                <span className={styles.place}>{index + 1}</span>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.tableContainer}>
          <table className={styles.creatorsTable}>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Followers</th>
                <th>Characters</th>
                <th>Total Scores</th>
              </tr>
            </thead>
            <tbody>
              {creators.slice(0, 24).map((creator, index) => (
                <tr key={creator.id}>
                  <td>{index + 1}</td>
                  <td>
                    <div 
                      className={styles.creatorInfoTable}
                      onClick={e => {
                        e.preventDefault();
                        navigate(`/creator/${creator.name}`)
                      }}
                    >
                      <img src={`${creator.pfp}`} alt={creator.name} />
                      <span>@{creator.name}</span>
                    </div>
                  </td>
                  <td>{creator.followers}</td>
                  <td>{creator.characters}</td>
                  <td>{creator.totalScores}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
}