import { useEffect, useState, useRef, useCallback  } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/Favorites.module.scss';
import { useSelector } from 'react-redux';
import axiosRequest from '../utils/request';

import updateImageUrl from '../utils/updateImageUrl'

const ImageCardSkeleton = () => (
  <div className={`${styles.characterCard} ${styles.skeleton}`}>
    <div className={styles.imageContainer}>
      <div className={styles.skeletonImage}></div>
      <div className={`${styles.likes} ${styles.skeletonText}`}></div>
    </div>
    <div className={`${styles.skeletonText} ${styles.skeletonTitle}`}></div>
    <div className={`${styles.skeletonText} ${styles.skeletonDescription}`}></div>
    <div className={styles.cardFooter}>
      <div className={`${styles.skeletonText} ${styles.skeletonTag}`}></div>
      <div className={`${styles.skeletonText} ${styles.skeletonMessages}`}></div>
    </div>
  </div>
);

const ThreeDotMenu = ({ image, onEdit, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(image);
    setIsOpen(false);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(image);
    setIsOpen(false);
  };

  return (
    <div className={styles.threeDotMenu}>
      <button onClick={toggleMenu} className={styles.menuButton}>⋮</button>
      {isOpen && (
        <div className={styles.menuContent}>
          <button onClick={handleEdit}>Edit</button>
          <button onClick={handleDelete}>Delete</button>
        </div>
      )}
    </div>
  );
};

export default function YourImages() {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(true);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [modalImage, setModalImage] = useState(null);

  const user = useSelector(state => state.userData);

  const observer = useRef();
  const lastImageElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchImages();
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      setLoading(true);
      const startTime = Date.now();

      let jwt = localStorage.getItem('jwt')

      const response = await axiosRequest('get', `images?page=${page}`, null, jwt);

      if (response.data.images) {
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime < 500) {
          await new Promise(resolve => setTimeout(resolve, 500 - elapsedTime));
        }

        setImages(prevImages => [...prevImages, ...response.data.images]);
        setHasMore(response.data.hasNextPage);
        setPage(prevPage => prevPage + 1);
      } else {
        setError('Failed to fetch images');
      }
    } catch (error) {
      console.log('Error fetching images:', error);
      setError('An error occurred while fetching images');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (image) => {
    // Implement edit functionality here
    console.log('Edit image:', image);
  };

  const handleDelete = (image) => {
    // Implement delete functionality here
    console.log('Delete image:', image);
  };

  const openModal = (imageUrl, event) => {
    event.preventDefault();
    console.log('Modal Image -> ', imageUrl);
    setModalImage(imageUrl);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  const renderCharacterCards = () => {
    if (loading && images.length === 0) {
      return Array(8).fill().map((_, index) => (
        <ImageCardSkeleton key={`skeleton-${index}`} />
      ));
    }

    return images.map((image, index) => (
      <div
        key={index}
        className={styles.characterCard}
        onClick={e => {
          e.preventDefault();
          openModal(image.imageUrl, e)
        }}
        ref={index === images.length - 1 ? lastImageElementRef : null}
      >
        <div className={styles.imageContainer}>
          <img src={updateImageUrl(image.imageUrl) || "/portal.gif"} alt={image.label} />
          {image.public && <div className={styles.deluxeTag}>Public</div>}
          <ThreeDotMenu
            image={image}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </div>
        <h3>{image.label}</h3>
        <p>{image.label}</p>
        <div className={styles.cardFooter}>
        </div>
      </div>
    ));
  };

  return (
    <>
      {!loggedIn ? (
        <></>
      ) : (
        <DashboardLayout headerStyle={{ padding: '0px 0px' }} title="Your Images">
          <div className={styles.container}>
            {images.length > 0 || loading ? (
              <>
                <div className={styles.filterRow}>
                  <span
                    className={styles.discoverNew}
                    onClick={e => {
                      e.preventDefault();
                      navigate('/search')
                    }}
                  >← discover new images</span>
                </div>
                <div className={styles.characterGrid}>
                  {renderCharacterCards()}
                </div>
                {loading && <div className={styles.loadingIndicator}>Loading more...</div>}
              </>
            ) : (
              <div className={styles.emptyState}>
                <h2>It's a bit empty here</h2>
                <p>Get started by uploading your first image</p>
                <button className={styles.exploreButton}>Upload Image</button>
              </div>
            )}
            {error && <div className={styles.error}>{error}</div>}
          </div>
          {modalImage && (
            <div className={styles.modal} onClick={closeModal}>
              <img
                src={modalImage}
                alt="Enlarged character"
                className={styles.modalContent}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          )}
        </DashboardLayout>
      )}
    </>
  );
}