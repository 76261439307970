import { useState } from 'react';
import Title from '../components/Title/Title.js';
import styles from "../assets/pages/Login.module.scss";
import Button from '../components/Button/Button';
import Link from '../components/Link/Link.js';
import SecurityInput from '../components/SecurityInput/SecurityInput';
import { useNavigate, useLocation } from 'react-router-dom';

import axiosRequest from '../utils/request.js';

import { toast } from 'react-toastify';

import DashboardLayout from '../components/DashboardLayout/DashboardLayout';

export default function Verify() {
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState(false);
  const [code, setCode] = useState();

  async function handleVerify() {
    try {
      if (code == undefined) {
        toast.error('Must fill out all fields!');
        return;
      };

      if (code.length < 6) {
        toast.error('Enter full code.');
        return;
      };

      let result = await axiosRequest('post', 'verify', { token: code });

      if (result.data.success) {
        if(location.state.redirectPath) {
          navigate('/login', { state: { redirectPath: location.state.redirectPath } });
        } else {
          navigate('/login');
        }
        return;
      } else {
        setError(result.data.msg);
        return;
      }
    } catch (e) {
      console.log('Error at verify call -> ', e);
      setError('Unknown error verifying email.');
      return;
    }
  }

  return (
    <DashboardLayout>
      <div className={styles.loginPage}>
        <div className={styles.container}>
          <Title>Verify Email</Title>
          <p style={{ color: '#fff' }} className={styles.subtitle}>Enter the code sent to {location.state == null ? 'your email.' : location.state.email}</p>
          <div className={styles.elementWrapper}>
            <SecurityInput onChange={setCode} error={error} />
          </div>
          <div className={styles.elementWrapper}>
            <Button style={{ backgroundColor: '#f77cb3', color: '#fff' }} onClick={() => handleVerify()}>Submit</Button>
          </div>
          <p style={{ color: '#fff' }} className={styles.centered}>Having issues? <Link style={{ }} to={'/'} onClick={e => {
            e.preventDefault();
            window.open('https://discord.com/invite/mindscapeis', '_target');
          }}>Open a Ticket</Link></p>
        </div>
      </div>
    </DashboardLayout>
  );
}
