import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/Favorites.module.scss';
import axiosRequest from '../utils/request';

import { useNavigate } from 'react-router-dom';

import formatNumber from '../utils/formatNumber';

import updateImageUrl from '../utils/updateImageUrl';

const CharacterCardSkeleton = () => (
  <div className={`${styles.characterCard} ${styles.skeleton}`}>
    <div className={styles.imageContainer}>
      <div className={styles.skeletonImage}></div>
      <div className={`${styles.likes} ${styles.skeletonText}`}></div>
    </div>
    <div className={`${styles.skeletonText} ${styles.skeletonTitle}`}></div>
    <div className={`${styles.skeletonText} ${styles.skeletonDescription}`}></div>
    <div className={styles.cardFooter}>
      <div className={`${styles.skeletonText} ${styles.skeletonTag}`}></div>
      <div className={`${styles.skeletonText} ${styles.skeletonMessages}`}></div>
    </div>
  </div>
);

export default function Favorites() {
  const navigate = useNavigate();
  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const user = useSelector(state => state.userData);

  useEffect(() => {
    fetchCharacters();
  }, []);

  const fetchCharacters = async () => {
    try {
      setLoading(true);
      const startTime = Date.now();

      const jwt = localStorage.getItem('jwt');
      
      const response = await axiosRequest('get', `characters/followed?page=${page}&limit=20`, null, jwt);

      if (response.data.success) {
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime < 1000) {
          await new Promise(resolve => setTimeout(resolve, 1000 - elapsedTime));
        }
        
        setCharacters(prevCharacters => [...prevCharacters, ...response.data.characters]);
        setHasMore(response.data.hasMore);
        setPage(prevPage => prevPage + 1);
      } else {
        setError('Failed to fetch characters');
      }
    } catch (error) {
      console.log('Error fetching characters:', error);
      setError('An error occurred while fetching characters');
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (!loading && hasMore) {
      fetchCharacters();
    }
  };

  const renderCharacterCards = () => {
    if (loading && characters.length === 0) {
      return Array(8).fill().map((_, index) => (
        <CharacterCardSkeleton key={`skeleton-${index}`} />
      ));
    }

    return characters.map((character, index) => (
      <div 
        key={index} 
        className={styles.characterCard}
        onClick={e => {
          e.preventDefault();
          navigate(`/character/${character['_id']}`);
        }}
      >
        <div className={styles.imageContainer}>
          <img src={updateImageUrl(character.imageUrl) || "/placeholder.avif"} alt={character.name} />
          <div className={styles.likes}>♥ {character.likes}</div>
        </div>
        <h3>{character.name}</h3>
        <p>{character.description.slice(0, 150)}{character.description.length > 150 ? '...' : ''}</p>
        <div className={styles.cardFooter}>
          {character.nsfw && <span className={styles.nsfwTag}>NSFW</span>}
          <span className={styles.messages}>{(formatNumber(character.messages) || 0).toLocaleString()} 💬</span>
        </div>
      </div>
    ));
  };

  return (
    <DashboardLayout headerStyle={{ padding: '0px 0px' }} title="Your Favorite Characters">
      <div className={styles.container}>
        {characters.length > 0 || loading ? (
          <>
            <div className={styles.filterRow}>
              <span 
                className={styles.discoverNew}
                onClick={e => {
                  e.preventDefault();
                  navigate('/search')
                }}
              >← discover new characters</span>
            </div>
            <div className={styles.characterGrid}>
              {renderCharacterCards()}
            </div>
            {hasMore && (
              <button onClick={loadMore} className={styles.loadMoreButton} disabled={loading}>
                {loading ? 'Loading...' : 'Load More'}
              </button>
            )}
          </>
        ) : (
          <div className={styles.emptyState}>
            <h2>It's a bit empty here</h2>
            <p>You don't follow any character</p>
            <button className={styles.exploreButton}>Explore characters</button>
          </div>
        )}
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </DashboardLayout>
  );
}