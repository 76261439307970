import styles from '../assets/pages/Search.module.scss';

import React, { useEffect, useState, useCallback, useRef } from 'react';

import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import CustomDropdown from '../components/Dropdown';

import { Link, useNavigate, } from 'react-router-dom';
import sleep from '../utils/sleep';
import axiosRequest from '../utils/request';
import Input from "../components/Input/Input";

import updateImageUrl from '../utils/updateImageUrl';

import AgeConfirmModal from '../components/AgeConfirmModal';

import { useSelector, useDispatch } from 'react-redux';

import formatNumber from '../utils/formatNumber';

const NSFWOverlay = ({ onShow, likes }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.content}>
        <p className={styles.ratingText}>This Character is rated XXX</p>
        <button className={styles.showButton} onClick={onShow}>Show</button>
      </div>
      <div className={styles.likes}>
        <span>♥</span> {likes}
      </div>
    </div>
  );
};

export default function SearchCharacters() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const observer = useRef();
  const loggedIn = useSelector(state => state.loggedIn);

  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [activeFilters, setActiveFilters] = useState([]);
  const [showAllCategories, setShowAllCategories] = useState(false);

  const [searchKeywords, setSearchKeywords] = useState('');

  const [sortBy, setSortBy] = useState('Most Liked');
  const sortOptions = ['Most Liked', 'Popular', 'Trending', 'Recent Hits', 'New'];


  const allCategories = [
    'Trending', 'Top Rated', 'hasVoice', 'Deluxe', 'Female', 'NSFW', 'Scenario', 'Dominant', 'Submissive',
    'Erotic Roleplay', 'Fictional', 'Multiple', 'Original Character (OC)', 'Petite', 'Breeding', 'Femdom', 'Milf', 'Straight',
    'Cheating', 'RPG', 'Monster Girl', 'Cuckold', 'Bully', 'Non-human', 'Switch', 'Anime', 'Magical', 'BBW',
    'Monster', 'Furry', 'Game', 'Hentai', 'Villain', 'Chastity', 'Hero', 'Elf', 'Tsundere', 'Yandere', 'Kuudere',
    'Royalty', 'Assistant', 'Robot', 'Object', 'Non-binary', 'Deredere', 'Feet', 'Movie', 'Arab', 'Myth',
    'Muslim', 'Religion', 'Historical', 'Giant', 'Succubus', 'Lesbian', 'Action', 'Alien', 'VTuber', 'Dandere',
    'Non-English', 'Fandom', 'Books', 'Philosophy', 'Manga', 'Politics', 'Asexual', 'Folklore', 'Dilf', 'Detective',
    'Seinen'
  ];

  const visibleCategories = showAllCategories ? allCategories : allCategories.slice(0, 10);

  const lastCharacterElementRef = useCallback(node => {
    if (!loggedIn) return;
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  useEffect(() => {
    fetchCharacters();
  }, [page, sortBy, activeFilters, searchKeywords]);

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
    setPage(1); // Reset to first page when changing sort
  };

  const fetchCharacters = async () => {
    try {
      setLoading(true);
      const jwt = localStorage.getItem('jwt');
      let sortValue;
      switch (sortBy) {
        case 'Most Liked':
          sortValue = 'mostLiked';
          break;
        case 'Popular':
          sortValue = 'popular';
          break;
        case 'New':
          sortValue = 'new';
          break;
        default:
          sortValue = 'mostLiked';
      }
      const params = new URLSearchParams({
        page: page.toString(),
        limit: '20',
        sort: sortValue,
        tags: activeFilters.map(filter => filter.toLowerCase()).join(','),
        keywords: searchKeywords
      });

      const endpoint = `search?${params.toString()}`;
      const result = await axiosRequest('get', endpoint, null, jwt);

      if (result && result.data && result.data.characters) {
        if (page === 1) {
          setCharacters(result.data.characters);
        } else {
          setCharacters(prev => [...prev, ...result.data.characters]);
        }
        setHasMore(result.data.currentPage < result.data.totalPages);
      } else {
        throw new Error('Failed to fetch characters');
      }
    } catch (error) {
      console.error('Error fetching characters:', error);
    } finally {
      setLoading(false);
    }
  };

  const toggleFilter = (filter) => {
    setActiveFilters(prev =>
      prev.includes(filter) ? prev.filter(f => f !== filter) : [...prev, filter]
    );
    setPage(1); // Reset to first page when changing filters
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1); // Reset to first page when searching
    fetchCharacters();
  };

  const nsfw = useSelector(state => state.nsfw);
  const [nsfwEnabled, setNSFW] = useState(nsfw);
  const [showAgeModal, setShowAgeModal] = useState(false);

  const handleShowNSFW = () => {
    setShowAgeModal(true);
  };

  const handleConfirmAge = () => {
    dispatch({
      type: 'UPDATE_NSFW',
      data: true
    });
    setNSFW(true);
    setShowAgeModal(false);
  };

  useEffect(() => {
    setNSFW(nsfw);
  }, [nsfw]);

  return (
    <>
      {(
        <DashboardLayout title={''}>
          <div className={`${styles["exploreSection"]}`}>
          {!loggedIn && (
                <div className={styles.premiumOverlay}>
                  <div className={styles.premiumContent}>
                    <p><span role="img" aria-label="diamond">💎</span> Sign in to view characters</p>
                    <p><small>ITS FREE — Sign in to unlock search. You need to sign in to chat anyways. 🤷</small></p>
                    <button onClick={() => navigate('/login')} className={styles.subscribeButton}>
                      Login
                    </button>
                  </div>
                </div>
              )}
            <div className={styles["exploreHeader"]}>
              <h2>Explore 🔍</h2>
              <CustomDropdown
                options={sortOptions}
                value={sortBy}
                onChange={handleSortChange}
              />
              { /*<button className={styles["discoverNew"]}>← discover new characters</button> */}
            </div>

            <div onSubmit={handleSearch} className={styles["searchRow"]}>
              <Input
                type="text"
                value={searchKeywords}
                style={{ width: '100%' }}
                onChange={(e) => setSearchKeywords(e.target.value)}
                placeholder="Search characters..."
                id="search-input"
              />
              <button type="submit" className={styles["searchButton"]}>🔍</button>
            </div>

            <div className={styles["filterSection"]}>
              {visibleCategories.map(category => (
                <button
                  key={category}
                  className={`${styles.filterButton} ${activeFilters.includes(category) ? styles.active : ''}`}
                  onClick={() => toggleFilter(category)}
                >
                  {category}
                </button>
              ))}
              <button
                className={styles["showMoreButton"]}
                onClick={() => setShowAllCategories(!showAllCategories)}
              >
                {showAllCategories ? "Show less" : "Show more"}
              </button>
            </div>

            <div className={`${styles.characterGrid} ${loggedIn ? '' : styles.blurredOverlay} `}>
              {characters.map((character, index) => (
                <div
                  key={character._id}
                  ref={index === characters.length - 1 ? lastCharacterElementRef : null}
                  className={styles.characterCard}
                  onClick={(e) => {
                    e.preventDefault();
                    
                    if (character.nsfw && !nsfwEnabled) return;

                    navigate(`/character/${character['_id']}`, { state: character });
                  }}
                >
                  <div className={styles.imageContainer}>
                    <img src={updateImageUrl(character.imageUrl) || "/placeholder.avif"} alt={character.name} />
                    {character.nsfw && !nsfwEnabled && (
                      <NSFWOverlay onShow={handleShowNSFW} likes={character.likes} />
                    )}
                    <div className={styles.likes}>♥ {character.likes}</div>
                    {character.nsfw && <div className={styles.nsfwTag}>NSFW</div>}
                  </div>
                  <h3>{character.name}</h3>
                  <p>{character.description}</p>
                  <div className={styles.cardFooter}>
                    <span className={styles.messages}>{(formatNumber(character.messages) || 0).toLocaleString()} 💬</span>
                  </div>
                </div>
              ))}
            </div>
            {loading && <div>Loading...</div>}
          </div>
        </DashboardLayout>
      )}
      {
        <AgeConfirmModal
          isOpen={showAgeModal}
          onClose={() => setShowAgeModal(false)}
          onConfirm={handleConfirmAge}
        />
      }
    </>
  );
}