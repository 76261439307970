import React from 'react';
import styles from '../../assets/pages/CreateImg.module.scss';

// Import these from wherever they are defined in your project
import { bodyTypeImages, breastSize, gender, genderImages, sfwPose, sfwPoseImages, nsfwPose, nsfwPoseImages, face, ethnicity, age, hairColor, bodyType, style, styleImages } from '../../resources/imgFormArrays';

export default function ImageGenerationForm({ imageGenerationData, handleImageGenerationChange }) {
  return (
    <div className={styles.imageGenerationForm}>
      <div className={styles.formGroup}>
        <h3 style={{ color: '#fff', fontSize: '14px', fontWeight: 'bold', marginBottom: '15px' }}>Choose Gender</h3>
        <div className={styles.filterSection}>
          {gender.map(element => (
            <button
              key={element}
              className={`${styles.imageButton} ${imageGenerationData.gender === element ? styles.active : ''}`}
              style={{ backgroundImage: `url(${genderImages[element]})` }}
              onClick={() => handleImageGenerationChange('gender', element)}
            >
              <span className={styles.buttonLabel}>{element}</span>
            </button>
          ))}
        </div>
      </div>
      <div className={styles.formGroup}>
        <h3 style={{ color: '#fff', fontSize: '14px', fontWeight: 'bold', marginBottom: '15px' }}>Choose Ethnicity</h3>
        <div className={styles.filterSection}>
          {ethnicity.map(element => (
            <button
              key={element}
              className={`${styles.filterButton} ${imageGenerationData.ethnicity === element ? styles.active : ''}`}
              onClick={() => handleImageGenerationChange('ethnicity', element)}
            >
              {element}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.formGroup}>
        <h3 style={{ color: '#fff', fontSize: '14px', fontWeight: 'bold', marginBottom: '15px' }}>Choose Age</h3>
        <div className={styles.filterSection}>
          {age.map(element => (
            <button
              key={element}
              className={`${styles.filterButton} ${imageGenerationData.age === element ? styles.active : ''}`}
              onClick={() => handleImageGenerationChange('age', element)}
            >
              {element}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.formGroup}>
        <h3 style={{ color: '#fff', fontSize: '14px', fontWeight: 'bold', marginBottom: '15px' }}>Choose Hair Color</h3>
        <div className={styles.filterSection}>
          {hairColor.map(element => (
            <button
              key={element}
              className={`${styles.filterButton} ${imageGenerationData.hairColor === element ? styles.active : ''}`}
              onClick={() => handleImageGenerationChange('hairColor', element)}
            >
              {element}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.formGroup}>
        <h3 style={{ color: '#fff', fontSize: '14px', fontWeight: 'bold', marginBottom: '15px' }}>Choose Facial Expression</h3>
        <div className={styles.filterSection}>
          {face.map(element => (
            <button
              key={element}
              className={`${styles.filterButton} ${imageGenerationData.face === element ? styles.active : ''}`}
              onClick={() => handleImageGenerationChange('face', element)}
            >
              {element}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.formGroup}>
        <h3 style={{ color: '#fff', fontSize: '14px', fontWeight: 'bold', marginBottom: '15px' }}>Choose Pose</h3>
        <h4 style={{ color: '#fff', fontSize: '14px', fontWeight: 'bold', marginBottom: '15px' }}>SFW Selections</h4>
        <div className={styles.filterSection}>
          {sfwPose.map(element => (
            <button
              key={element}
              className={`${styles.imageButton} ${imageGenerationData.pose === element ? styles.active : ''}`}
              style={{ backgroundImage: `url(${sfwPoseImages[element]})` }}
              onClick={() => handleImageGenerationChange('pose', element)}
            >
              <span className={styles.buttonLabel}>{element}</span>
            </button>
          ))}
        </div>
        <h4 style={{ color: '#fff', fontSize: '14px', fontWeight: 'bold', marginBottom: '15px' }}>NSFW Selections</h4>
        <div className={styles.filterSection}>
          {nsfwPose.map(element => (
            <button
              key={element}
              className={`${styles.imageButton} ${imageGenerationData.pose === element ? styles.active : ''}`}
              style={{ backgroundImage: `url(${nsfwPoseImages[element]})` }}
              onClick={() => handleImageGenerationChange('pose', element)}
            >
              <span className={styles.buttonLabel}>{element}</span>
            </button>
          ))}
        </div>
      </div>
      <div className={styles.formGroup}>
        <h3 style={{ color: '#fff', fontSize: '14px', fontWeight: 'bold', marginBottom: '15px' }}>Choose Breast Size</h3>
        <div className={styles.filterSection}>
          {breastSize.map(element => (
            <button
              key={element}
              className={`${styles.filterButton} ${imageGenerationData.breastSize === element ? styles.active : ''}`}
              onClick={() => handleImageGenerationChange('breastSize', element)}
            >
              {element}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.formGroup}>
        <h3 style={{ color: '#fff', fontSize: '14px', fontWeight: 'bold', marginBottom: '15px' }}>Choose Body Type</h3>
        <div className={styles.filterSection}>
          {bodyType.map(element => (
            <button
              key={element}
              className={`${styles.imageButton} ${imageGenerationData.bodyType === element ? styles.active : ''}`}
              style={{ backgroundImage: `url(${bodyTypeImages[element]})` }}
              onClick={() => handleImageGenerationChange('bodyType', element)}
            >
              <span className={styles.buttonLabel}>{element}</span>
            </button>
          ))}
        </div>
      </div>
      <div className={styles.formGroup}>
        <h3 style={{ color: '#fff', fontSize: '14px', fontWeight: 'bold', marginBottom: '15px' }}>Choose Image Style</h3>
        <div className={styles.filterSection}>
          {style.map(element => (
            <button
              key={element}
              className={`${styles.imageButton} ${imageGenerationData.style === element ? styles.active : ''}`}
              style={{ backgroundImage: `url(${styleImages[element]})` }}
              onClick={() => handleImageGenerationChange('style', element)}
            >
              <span className={styles.buttonLabel}>{element}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}