import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/DynamicCharacter.module.scss';
import axiosRequest from '../utils/request';

import UserAvatar from '../components/UserAvatar';

import { useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import formatNumber from '../utils/formatNumber';

import { toast } from 'react-toastify';

import updateImageUrl from '../utils/updateImageUrl';

const SkeletonLoader = () => (
  <div className={styles.skeletonWrapper}>
    <div className={styles.skeletonHeader}>
      <div className={styles.skeletonImage}></div>
      <div className={styles.skeletonInfo}>
        <div className={styles.skeletonName}></div>
        <div className={styles.skeletonStats}></div>
        <div className={styles.skeletonMeta}></div>
        <div className={styles.skeletonDescription}></div>
        <div className={styles.skeletonTags}></div>
      </div>
    </div>
    <div className={styles.skeletonButtons}></div>
    <div className={styles.skeletonTabs}></div>
  </div>
);

export default function DynamicCharacter() {
  const navigate = useNavigate();
  const [character, setCharacter] = useState(null);
  const [loading, setLoading] = useState(true);
  const { charactername } = useParams();

  const loggedIn = useSelector(state => state.loggedIn);

  const [activeTab, setActiveTab] = useState('reviews');
  const [name, setName] = useState('');
  const [userReaction, setUserReaction] = useState(null);
  const [reviewComment, setReviewComment] = useState('');
  const [reviewRating, setReviewRating] = useState(5);
  const [submittingReview, setSubmittingReview] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followCount, setFollowCount] = useState(0);

  const [modalImage, setModalImage] = useState(null);

  const openModal = (imageUrl, event) => {
    event.preventDefault();
    setModalImage(imageUrl);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  const user = useSelector(state => state.userData);

  const location = useLocation();
  const passedCharacter = location.state;

  useEffect(() => {
    const fetchCharacter = async () => {
      try {
        setLoading(true);
        const jwt = localStorage.getItem('jwt');
        const result = await axiosRequest('get', `character/${charactername}`, null, jwt);
        if (result.data && result.data.character) {
          setCharacter(result.data.character);
          setName(result.data.character.name);
          setUserReaction(result.data.userReaction);

          setIsFollowing(user.followedCharacters.includes(charactername));
          setFollowCount(result.data.character.follows);
        } else {
          throw new Error('Failed to fetch character data');
        }
      } catch (error) {
        console.error('Error fetching character:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        setLoading(false);
      }
    };

    if (charactername) {
      fetchCharacter();
    }
  }, [charactername]);

  const handleReaction = async (reactionType) => {
    try {
      const jwt = localStorage.getItem('jwt');
      const result = await axiosRequest('post', `character/${charactername}/react`, { reactionType }, jwt);
      if (result.data && result.data.success) {
        setCharacter(prevCharacter => ({
          ...prevCharacter,
          likes: result.data.likes,
          dislikes: result.data.dislikes
        }));
        setUserReaction(reactionType === userReaction ? null : reactionType);
      } else {
        throw new Error('Failed to update reaction');
      }
    } catch (error) {
      console.error('Error updating reaction:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleFollow = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const result = await axiosRequest('post', `character/${character._id}/follow`, null, jwt);
      if (result.data && result.data.success) {
        setIsFollowing(result.data.isFollowing);
        setFollowCount(result.data.followCount);
      } else {
        throw new Error('Failed to update follow status');
      }
    } catch (error) {
      console.error('Error updating follow status:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    setSubmittingReview(true);
    try {
      const jwt = localStorage.getItem('jwt');
      const result = await axiosRequest('post', `character/${charactername}/review`, {
        comment: reviewComment,
        rating: reviewRating
      }, jwt);

      if (result.data && result.data.success) {
        const newReview = result.data.review;
        setCharacter(prevCharacter => {
          const updatedReviews = [...(prevCharacter.reviews || []), newReview];
          const newAverageRating = updatedReviews.reduce((sum, review) => sum + review.rating, 0) / updatedReviews.length;

          return {
            ...prevCharacter,
            reviews: updatedReviews,
            reviewsCount: (prevCharacter.reviewsCount || 0) + 1,
            averageRating: newAverageRating
          };
        });
        setReviewComment('');
        setReviewRating(5);
        // Optionally, show a success message
      } else {
        throw new Error('Failed to submit review');
      }
    } catch (error) {
      console.error('Error submitting review:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setSubmittingReview(false);
    }
  };

  if (loading) {
    return (
      <DashboardLayout title={''}>
        <SkeletonLoader />
      </DashboardLayout>
    );
  }

  if (!character) {
    return (
      <DashboardLayout title="Error">
        <p>Failed to load character data.</p>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout headerStyle={{ padding: '0px', marginBottom: '0px' }}>
      <div className={styles.characterProfile}>
        <div className={styles.profileHeader}>
          <img
            src={character.imageUrl ? updateImageUrl(character.imageUrl) : '/portal.gif'}
            alt={character.name}
            className={styles.characterImage}
            onClick={(e) => openModal(updateImageUrl(character.imageUrl), e)}
          />
          <div className={styles.characterInfo}>
            <h1>{character.name}</h1>
            <div className={styles.stats}>
              <span>💬 {(formatNumber(character.messages) || 0)?.toLocaleString()}</span>
              <span>🗨 {(formatNumber(character.chats) || 0)?.toLocaleString()}</span>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  if (loggedIn) {
                    handleReaction('like')
                  }
                }}
                style={{ cursor: 'pointer', color: userReaction === 'like' ? '#5d5dff' : 'inherit' }}
              >
                👍 {(formatNumber(character.likes) || 0)?.toLocaleString()}
              </span>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  if (loggedIn) {
                    handleReaction('dislike')
                  }
                }}
                style={{ cursor: 'pointer', color: userReaction === 'dislike' ? '#ff5d5d' : 'inherit' }}
              >
                👎 {(formatNumber(character.dislikes) || 0)?.toLocaleString()}
              </span>
            </div>
            <div className={styles.characterMeta}>
              <span
                className={styles.username}
                onClick={e => {
                  e.preventDefault();

                  navigate(`/creator/${character.creator.username}`)
                }}
              >@{character.creator.username || 'username'}</span>
              <button
                className={`${styles.followButton} ${isFollowing ? styles.following : ''}`}
                onClick={e => {
                  e.preventDefault();
                  if (loggedIn) {
                    handleFollow()
                  } else {
                    toast.info('Login to follow 😗')
                  }
                }}
              >
                {isFollowing ? 'Unfollow' : 'Follow'}
              </button>
              <span>{followCount} followers</span>
              <div className={styles.column}>
                <small><b>Published at </b></small>
                <b>{new Date(character.createdAt).toLocaleDateString()}</b>
              </div>
              <div className={styles.column}>
                <small><b>Last Updated </b></small>
                <b>{new Date(character.updatedAt).toLocaleDateString()}</b>
              </div>
            </div>
            <p>{character.description}</p>
            <div className={styles.tags}>
              {character.tags.map((tag, index) => (
                <span key={index} className={styles.tag}>{tag}</span>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <button
            className={styles.primaryButton}
            onClick={e => {
              e.preventDefault();

              if (loggedIn) {
                let link = `/character/${charactername}/chat`;

                /*if(loggedIn) {
                  link = link + `/${user['_id']}`
                }*/
                navigate(link);
              } else {
                toast.info('Login to chat 😛')
              }
            }}
          >
            Chat 💬
          </button>
          <button
            className={styles.secondaryButton}
            onClick={e => {
              e.preventDefault();
              //toast.info('That feature is coming soon! ⏳')
              navigate('/image/create')
            }}
          >
            Generate Pictures ✨
          </button>
        </div>
        <div className={styles.tabContainer}>
          <div className={styles.tabs}>
            <button className={activeTab === 'reviews' ? styles.activeTab : ''} onClick={() => setActiveTab('reviews')}>⭐ Reviews</button>
            <button className={activeTab === 'aiGenerator' ? styles.activeTab : ''} onClick={() => setActiveTab('aiGenerator')}>🎨 AI Image Generator</button>
            <button className={activeTab === 'memories' ? styles.activeTab : ''} onClick={() => setActiveTab('memories')}>💾 Memories</button>
          </div>
          {activeTab === 'reviews' && (
            <div className={styles.reviews}>
              <h2>
                {character.reviewsCount || 0} reviews |
                Average Rating: {character.averageRating ? character.averageRating.toFixed(1) : 'N/A'}
              </h2>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  if (loggedIn) {
                    handleReviewSubmit(e)
                  } else {
                    toast.info('Login to leave a review!')
                  }
                }}
                className={styles.reviewForm}
              >
                <textarea
                  value={reviewComment}
                  onChange={(e) => setReviewComment(e.target.value)}
                  placeholder="Write your review here..."
                  required
                />
                <div className={styles.row}>
                  <div className={styles.ratingSelector}>
                    {[5, 4, 3, 2, 1].map((star) => (
                      <button
                        key={star}
                        className={`${styles.ratingOption} ${reviewRating === star ? styles.selected : ''}`}
                        onClick={() => {
                          if (loggedIn) {
                            setReviewRating(star)
                          }
                        }
                        }
                      >
                        ★
                      </button>
                    ))}
                  </div>
                  <button
                    className={styles.submitButton}
                    type="submit"
                    disabled={submittingReview}>
                    {submittingReview ? 'Submitting...' : 'Submit Review'}
                  </button>
                </div>
              </form>
              {character.reviews && character.reviews.length > 0 ? (
                character.reviews.map((review, index) => (
                  <div key={index} className={styles.review}>
                    <div className={styles.reviewHeader}>
                      <div className={styles.reviewerInfo}>
                        <UserAvatar user={review.user} />
                        <span className={styles.reviewerName}>@{review.user.username}</span>
                      </div>
                      <div className={styles.reviewRating}>
                        {'⭐'.repeat(review.rating)}
                        <span className={styles.numericRating}>({review.rating}/5)</span>
                      </div>
                    </div>
                    <p className={styles.reviewComment}>{review.comment}</p>
                  </div>
                ))
              ) : (
                <p>No reviews yet. Be the first to review!</p>
              )}
            </div>
          )}
          {activeTab === 'aiGenerator' && (
            <div className={styles.aiGenerator}>
              <h2>Generate your Picture <span className={styles.alpha}>Alpha</span></h2>
              {/* AI Generator content */}
            </div>
          )}
          {activeTab === 'memories' && (
            <div className={styles.memories}>
              <h2>Memories</h2>
              {/* Memories content */}
            </div>
          )}
        </div>
      </div>
      {modalImage && (
        <div className={styles.modal} onClick={closeModal}>
          <img
            src={modalImage}
            alt="Enlarged character"
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </DashboardLayout>
  );
}