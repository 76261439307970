import styles from './LabeledCheckbox.module.scss';
import clsx from 'clsx';

export default function LabeledCheckbox ({ children, ...rest }) {
  const checkboxWrapperClassName = clsx([
    styles.labeledCheckbox,
    rest.checked && styles.checked,
  ]);

  return (
    <label className={checkboxWrapperClassName}>
      <div className={styles.checkbox}></div>
      <input type="checkbox" {...rest} />
      { children }
    </label>
  )
}