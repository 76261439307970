import styles from './UserAvatar.module.scss'

const UserAvatar = ({ user, style }) => {
  const getInitialAvatar = (username = 'Mindscape') => {
    const initial = username.charAt(0).toUpperCase();
    const colors = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D'];
    const colorIndex = username.charCodeAt(0) % colors.length;
    const backgroundColor = colors[colorIndex];

    return (
      <div
      className={styles.initialAvatar}
        style={style ? {
          ...style,
          backgroundColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#ffffff',
          fontWeight: 'bold',
          fontSize: '14px'
        } : {
          backgroundColor,
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#ffffff',
          fontWeight: 'bold',
          fontSize: '14px'
        }}
      >
        {initial}
      </div>
    );
  };

  return (
    <div className={styles.userAvatar}>
      {user.pfp ? (
        <img
          src={user.pfp}
          alt={`${user.username}'s avatar`}
          style={{
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            objectFit: 'cover'
          }}
        />
      ) : (
        getInitialAvatar(user.username)
      )}
    </div>
  );
};

export default UserAvatar;