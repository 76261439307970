import React from 'react';
import styles from './AgeConfirmModal.module.scss';

export default function AgeConfirmModal({ isOpen, onClose, onConfirm }) {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>Confirm your age</h2>
        <p className={styles.modalText}>
          This content may not be suitable for all viewers. Please confirm you are of legal age to view adult content.
        </p>
        <div className={styles.modalActions}>
          <button className={`${styles.button} ${styles.cancelButton}`} onClick={onClose}>
            Go back
          </button>
          <button className={`${styles.button} ${styles.confirmButton}`} onClick={onConfirm}>
            I'm over 18
          </button>
        </div>
      </div>
    </div>
  );
}