import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';

import Link from '../../components/Link/Link.js';
import Input from '../../components/Input/Input.js';
import Title from '../../components/Title/Title.js';
import styles from "../../assets/pages/ResetPassword.module.scss";
import Button from '../../components/Button/Button.js';

import axiosRequest from '../../utils/request.js';

import { toast } from 'react-toastify';

export default function ResetPassword() {
  const navigate = useNavigate();
  const [sent, setSent] = useState(false);

  const [password, setPassword] = useState();
  const query = new URLSearchParams(window.location.search);

  async function handleReset() {
    try {
      let hasError = false;

      if (password == undefined) {
        hasError = true;
      };

      if (hasError) {
        toast.error('Must fill out all fields!');
        return;
      };

      let token = query.get('token');

      if (!token) {
        toast.error('Missing token. Please resubmit a reset request.');
        return;
      }

      let result = await axiosRequest('post', 'reset-password', { password, token });

      if (result.data.success) {
        setSent(true);
        return;
      } else {
        toast.error(result.data.msg);
        return;
      }
    } catch (e) {
      toast.error('Unknown error submitting password reset request.');
      return;
    }
  };

  return (
    <DashboardLayout
      title={'Reset Password'}
    >
      <div className={styles.resetPage}>
        <div className={styles.container}>
          {!sent && (
            <>
              <Title>Reset Password</Title>
              <div className={styles.elementWrapper}>
                <Input onChange={e => setPassword(e.target.value)} label={"New Password"} id={'password'} type={'password'} />
              </div>
              <div className={styles.elementWrapper}>
                <Button onClick={handleReset}>Submit</Button>
              </div>
              <p className={styles.centered}>Changed your mind? <Link style={{ color: '#fff' }} to={'/login'}>Return to Login</Link></p>
            </>
          )}
          {sent && (
            <>
              <Title>Success</Title>
              <p className={styles.subtitle}>Password reset.</p>
              <div className={styles.elementWrapper}>
                <Button onClick={() => navigate('/login')}>Return to Login</Button>
              </div>
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}