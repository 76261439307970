import React from 'react';
import styles from './SubModal.module.scss';

export default function SubscribeModal({ isOpen, onClose, onConfirm, bgImg = '/placeholder.avif' }) {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent} style={{ backgroundImage: `url(${bgImg})` }}>
        <div className={styles.modalContentInner}>
          <h2 className={styles.modalTitle}>You aren't subscribed!</h2>
          <p className={styles.modalText}>
            For less than a starbucks order or two you get to chat with all these baddies. Subscribe below 🥱👇 
          </p>
          <div className={styles.modalActions}>
            <button className={`${styles.button} ${styles.cancelButton}`} onClick={onClose}>
              Go back
            </button>
            <button 
              className={`${styles.button} 
              ${styles.confirmButton}`} 
              onClick={onConfirm}
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}