import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import Spinner from '../components/Spinner/Spinner';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/CreateImg.module.scss';

import axiosRequest from '../utils/request';

import { toast } from 'react-toastify';

import { bodyTypeImages, breastSize, gender, genderImages, sfwPose, sfwPoseImages, nsfwPose, nsfwPoseImages, face, ethnicity, age, hairColor, bodyType, style, styleImages } from '../resources/imgFormArrays';

export default function CreateImg() {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(true);
  const user = useSelector(state => state.userData);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    gender: '',
    ethnicity: '',
    age: '',
    hairColor: '',
    face: '',
    pose: '',
    breastSize: '',
    bodyType: '',
    style: '',
    prompt: '',
    label: ''
  });

  async function handleSubmit() {
    try {
      setLoading(true);

      let jwt = localStorage.getItem('jwt');
      if (!jwt) {
        throw new Error('No authentication token found');
      }

      const endpoint = `images/create`;
      const method = 'post';

      const result = await axiosRequest(method, endpoint, form, jwt);

      if (result.data && result.data.image) {
        toast.success('Image is queued up! You can view it in your gallery soon.');
        toast.success('Redirecting to gallery in 1s...');
        setForm({
          gender: '',
          ethnicity: '',
          age: '',
          hairColor: '',
          face: '',
          pose: '',
          breastSize: '',
          bodyType: '',
        });
        setLoading(false);
        setTimeout(() => {
          navigate(`/my-images`);
        }, 1250);
      } else {
        console.log('here -> ',);
        if (result.data.message) {
          toast.error(result.data.message);
        } else {
          toast.error('Image creation failed!');
        };
        setLoading(false);
      }
    } catch (e) {
      console.error('Error @ handleSubmit -> ', e);
      toast.error(e.response?.data?.message || `Error creating image. Please try again.`);

      setLoading(false);
    }
  }

  return (
    <>
      {
        !loggedIn ? <></> :
          <DashboardLayout
            title={'Create Image'}
          >
            <div className={styles.formContainer}>
              {(!user || user.subscriptionStatus === 'inactive') && (
                <div className={styles.premiumOverlay}>
                  <div className={styles.premiumContent}>
                    <p><span role="img" aria-label="diamond">💎</span> Sign up to Premium to create images</p>
                    <p><small>Free members can not create images. Subscribe to continue.</small></p>
                    <button onClick={() => navigate('/membership')} className={styles.subscribeButton}>
                      Subscribe
                    </button>
                  </div>
                </div>
              )}
              <form className={`${styles.form} ${user ? (user.subscriptionStatus === 'inactive' ? styles.blurred : '') : styles.blurred}`}>
                <div className={styles.formGroup}>
                  <h1 style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
                    Choose Gender
                  </h1>
                  <div className={styles.filterSection}>
                    {gender.map(element => (
                      <button
                        key={element}
                        className={`${styles.imageButton} ${form.gender === element ? styles.active : ''}`}
                        style={{ backgroundImage: `url(${genderImages[element]})` }}
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({ ...form, gender: element });
                        }}
                      >
                        <span className={styles.buttonLabel}>{element}</span>
                      </button>
                    ))}
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <h1
                    style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}
                  >
                    Choose Ethnicity
                  </h1>
                  <div className={styles.filterSection}>
                    {ethnicity.map(element => (
                      <button
                        key={element}
                        className={`${styles.filterButton} ${form.ethnicity == element ? styles.active : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          let newForm = { ...form };
                          newForm.ethnicity = element;

                          setForm(newForm);
                        }}
                      >
                        {element}
                      </button>
                    ))}
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <h1
                    style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}
                  >
                    Choose Age
                  </h1>
                  <div className={styles.filterSection}>
                    {age.map(element => (
                      <button
                        key={element}
                        className={`${styles.filterButton} ${form.age == element ? styles.active : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          let newForm = { ...form };
                          newForm.age = element;

                          setForm(newForm);
                        }}
                      >
                        {element}
                      </button>
                    ))}
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <h1
                    style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}
                  >
                    Choose Hair Color
                  </h1>
                  <div className={styles.filterSection}>
                    {hairColor.map(element => (
                      <button
                        key={element}
                        className={`${styles.filterButton} ${form.hairColor == element ? styles.active : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          let newForm = { ...form };
                          newForm.hairColor = element;

                          setForm(newForm);
                        }}
                      >
                        {element}
                      </button>
                    ))}
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <h1
                    style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}
                  >
                    Choose Facial Expression
                  </h1>
                  <div className={styles.filterSection}>
                    {face.map(element => (
                      <button
                        key={element}
                        className={`${styles.filterButton} ${form.face == element ? styles.active : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          let newForm = { ...form };
                          newForm.face = element;

                          setForm(newForm);
                        }}
                      >
                        {element}
                      </button>
                    ))}
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <h1 style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
                    Choose Pose
                  </h1>
                  <h1 style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>SFW Selections</h1>
                  <div className={styles.filterSection}>
                    {sfwPose.map(element => (
                      <button
                        key={element}
                        className={`${styles.imageButton} ${form.pose === element ? styles.active : ''}`}
                        style={{ backgroundImage: `url(${sfwPoseImages[element]})` }}
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({ ...form, pose: element });
                        }}
                      >
                        <span className={styles.buttonLabel}>{element}</span>
                      </button>
                    ))}
                  </div>
                  <h1 style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>NSFW Selections</h1>
                  <div className={styles.filterSection}>
                    {nsfwPose.map(element => (
                      <button
                        key={element}
                        className={`${styles.imageButton} ${form.pose === element ? styles.active : ''}`}
                        style={{ backgroundImage: `url(${nsfwPoseImages[element]})` }}
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({ ...form, pose: element });
                        }}
                      >
                        <span className={styles.buttonLabel}>{element}</span>
                      </button>
                    ))}
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <h1
                    style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}
                  >
                    Choose Breast Size
                  </h1>
                  <div className={styles.filterSection}>
                    {breastSize.map(element => (
                      <button
                        key={element}
                        className={`${styles.filterButton} ${form.breastSize == element ? styles.active : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          let newForm = { ...form };
                          newForm.breastSize = element;

                          setForm(newForm);
                        }}
                      >
                        {element}
                      </button>
                    ))}
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <h1 style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
                    Choose Body Type
                  </h1>
                  <div className={styles.filterSection}>
                    {bodyType.map(element => (
                      <button
                        key={element}
                        className={`${styles.imageButton} ${form.bodyType === element ? styles.active : ''}`}
                        style={{ backgroundImage: `url(${bodyTypeImages[element]})` }}
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({ ...form, bodyType: element });
                        }}
                      >
                        <span className={styles.buttonLabel}>{element}</span>
                      </button>
                    ))}
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <h1 style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
                    Choose Art Style
                  </h1>
                  <div className={styles.filterSection}>
                    {style.map(element => (
                      <button
                        key={element}
                        className={`${styles.imageButton} ${form.style === element ? styles.active : ''}`}
                        style={{ backgroundImage: `url(${styleImages[element]})` }}
                        onClick={(e) => {
                          e.preventDefault();
                          setForm({ ...form, style: element });
                        }}
                      >
                        <span className={styles.buttonLabel}>{element}</span>
                      </button>
                    ))}
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <label>Prompt</label>
                  <textarea
                    name="Prompt"
                    value={form.prompt}
                    onChange={(e) => {
                      let newFormData = { ...form, prompt: e.target.value };
                      setForm(newFormData);
                    }}
                    placeholder={`white laced lingerie, laying on bed, ass up`}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Label</label>
                  <textarea
                    name="Label"
                    value={form.label}
                    onChange={(e) => {
                      let newFormData = { ...form, label: e.target.value };
                      setForm(newFormData);
                    }}
                    placeholder={`Ashley - hot milf`}
                  />
                  <small>Helps you organize your created images.</small>
                </div>
                <div className={styles.formGroup}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (!loading) {
                        handleSubmit();
                      }
                    }}
                    type="submit"
                    className={styles.submitButton}
                  >
                    {loading ? <Spinner /> : 'Create Image'}
                  </button>
                </div>
              </form>
            </div>
          </DashboardLayout>
      }
    </>
  )
}

