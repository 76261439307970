export default function MenuIcon () {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Landing-Page-–-Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.8">
        <g id="Plans" transform="translate(-33.000000, -33.000000)" fill="#fff">
          <g id="Button-Enabled-Copy" transform="translate(20.000000, 20.000000)">
            <path d="M13,13.5 L37,13.5 L37,16.5 L13,16.5 Z M13,23.5 L37,23.5 L37,26.5 L13,26.5 Z M13,33.5 L37,33.5 L37,36.5 L13,36.5 Z" id="Combined-Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
