import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useEmblaCarousel from 'embla-carousel-react';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import CustomDropdown from '../components/Dropdown';
import styles from '../assets/pages/Home.module.scss';

import { Link, useNavigate } from 'react-router-dom';
import sleep from '../utils/sleep';
import axiosRequest from '../utils/request';

import updateImageUrl from '../utils/updateImageUrl';

import AgeConfirmModal from '../components/AgeConfirmModal';

import formatNumber from '../utils/formatNumber';
import Input from '../components/Input/Input';
import ModalStyle from '../components/SubscribeModal/SubModal.module.scss';

import { displaycharacters } from '../resources/homedisplay';

const NSFWOverlay = ({ onShow, likes }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.content}>
        <p className={styles.ratingText}>Click to View! ♥</p>
        <button className={styles.showButton} onClick={onShow}>Go to Character</button>
      </div>
      <div className={styles.likes}>
        <span>♥</span> {likes}
      </div>
    </div>
  );
};

function InstallModal({ isOpen, onClose, onConfirm, title, message }) {
  if (!isOpen) return null;


  return (
    <div className={ModalStyle.modalOverlay}>
      <div style={{ display: 'flex', justifyContent: 'center', height: 'auto' }} className={ModalStyle.modalContent}>
        <div className={ModalStyle.modalContentInner}>
          <h2 className={ModalStyle.modalTitle}>{title}</h2>
          <p className={ModalStyle.modalText}>
            {message}
          </p>
          <img style={{ 'margin': '25px' }} src="/install.gif" />
          <div className={ModalStyle.modalActions}>
            <button className={`${ModalStyle.button} ${ModalStyle.cancelButton}`} onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const observer = useRef();
  const loggedIn = useSelector(state => state.loggedIn);
  const [typingText, setTypingText] = useState('');
  const phrases = ['No limits.', 'Endless roleplay.', 'Infinite characters.'];
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [showInstallModal, setInstallModal] = useState(false);

  const [discoverApi, setDiscoverApi] = useState(null);

  const [discoverRef, discoverEmbla] = useEmblaCarousel({
    loop: true,
    align: 'start',
    slidesToScroll: 1,
  });

  useEffect(() => {
    if (discoverEmbla) setDiscoverApi(discoverEmbla);
  }, [discoverEmbla]);

  const scrollPrev = useCallback((api) => api && api.scrollPrev(), []);
  const scrollNext = useCallback((api) => api && api.scrollNext(), []);

  const [trendingRef, trendingApi] = useEmblaCarousel({ loop: false, align: 'start', containScroll: 'trimSnaps' });

  const [characters, setCharacters] = useState([...displaycharacters]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  /*const lastCharacterElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);*/

  useEffect(() => {
    //fetchCharacters();
  }, [page]);

  const fetchCharacters = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const result = await axiosRequest('get', `characters/tag/female?page=${page}&limit=20`, null, jwt);
      if (result.data && result.data.characters) {
        setCharacters(prev => [...prev, ...result.data.characters]);
        //setExploreCharacters(result.data.characters.slice(8, 17).reverse())
        setHasMore(result.data.hasMore);
      } else {
        throw new Error('Failed to fetch characters');
      }
    } catch (error) {
      console.error('Error fetching characters:', error);
    } finally {
      await sleep(500);
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    let isTyping = true;
    let charIndex = 0;

    const typeText = async () => {
      if (!isMounted) return;

      if (isTyping) {
        if (charIndex < phrases[currentPhraseIndex].length) {
          setTypingText(prev => phrases[currentPhraseIndex].slice(0, charIndex + 1));
          charIndex++;
          setTimeout(typeText, 100);
        } else {
          await sleep(2000);
          isTyping = false;
          typeText();
        }
      } else {
        if (charIndex > 0) {
          setTypingText(prev => prev.slice(0, -1));
          charIndex--;
          setTimeout(typeText, 50);
        } else {
          isTyping = true;
          setCurrentPhraseIndex((prev) => (prev + 1) % phrases.length);
          await sleep(500);
          typeText();
        }
      }
    };

    typeText();

    return () => {
      isMounted = false;
    };
  }, [currentPhraseIndex]);


  useEffect(() => {
    if (discoverApi) {
      const autoplay = setInterval(() => {
        if (discoverApi.canScrollNext()) {
          discoverApi.scrollNext();
        } else {
          discoverApi.scrollTo(0);
        }
      }, 3000);

      return () => clearInterval(autoplay);
    }
  }, [discoverApi]);

  const [activeFilters, setActiveFilters] = useState([]);
  const [showAllCategories, setShowAllCategories] = useState(false);

  const [sortBy, setSortBy] = useState('Most Liked');
  const sortOptions = ['Most Liked', 'Popular', 'Trending', 'Recent Hits', 'New'];

  const allCategories = [
    'Trending', 'Top Rated', 'hasVoice', 'Deluxe', 'Female', 'NSFW', 'Scenario', 'Dominant', 'Submissive',
    'Erotic Roleplay', 'Fictional', 'Multiple', 'Original Character (OC)', 'Petite', 'Breeding', 'Femdom', 'Milf', 'Straight',
    'Cheating', 'RPG', 'Monster Girl', 'Cuckold', 'Bully', 'Non-human', 'Switch', 'Anime', 'Magical', 'BBW',
    'Monster', 'Furry', 'Game', 'Hentai', 'Villain', 'Chastity', 'Hero', 'Elf', 'Tsundere', 'Yandere', 'Kuudere',
    'Royalty', 'Assistant', 'Robot', 'Object', 'Non-binary', 'Deredere', 'Feet', 'Movie', 'Arab', 'Myth',
    'Muslim', 'Religion', 'Historical', 'Giant', 'Succubus', 'Lesbian', 'Action', 'Alien', 'VTuber', 'Dandere',
    'Non-English', 'Fandom', 'Books', 'Philosophy', 'Manga', 'Politics', 'Asexual', 'Folklore', 'Dilf', 'Detective',
    'Seinen'
  ];

  const visibleCategories = showAllCategories ? allCategories : allCategories.slice(0, 10);

  const [exploreCharacters, setExploreCharacters] = useState([]);
  const [explorePage, setExplorePage] = useState(1);
  const [exploreHasMore, setExploreHasMore] = useState(true);

  useEffect(() => {
    fetchExploreCharacters();
  }, [explorePage, activeFilters, sortBy]);

  const fetchExploreCharacters = async () => {
    try {
      setLoading(true);
      const jwt = localStorage.getItem('jwt');
      const params = new URLSearchParams({
        page: explorePage,
        limit: 20,
        sort: sortBy.toLowerCase().replace(' ', ''),
        tags: activeFilters.map(filter => filter.toLowerCase()).join(','),
      });

      const result = await axiosRequest('get', `search?${params.toString()}`, null, jwt);
      if (result.data && result.data.characters) {
        if (explorePage === 1) {
          setExploreCharacters(result.data.characters);
          //setExploreCharacters(result.data.characters.slice(8, 17).reverse());
        } else {
          setExploreCharacters(prev => [...prev, ...result.data.characters]);
        }
        setExploreHasMore(result.data.currentPage < result.data.totalPages);
      } else {
        throw new Error('Failed to fetch characters');
      }
    } catch (error) {
      console.error('Error fetching explore characters:', error);
    } finally {
      setLoading(false);
    }
  };

  /*const lastExploreCharacterRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && exploreHasMore) {
        setExplorePage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, exploreHasMore]);*/

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
    setExplorePage(1);
  };

  const toggleFilter = (filter) => {
    setActiveFilters(prev =>
      prev.includes(filter) ? prev.filter(f => f !== filter) : [...prev, filter]
    );
    setExplorePage(1);
  };

  const nsfw = useSelector(state => state.nsfw);
  const [nsfwEnabled, setNSFW] = useState(nsfw);
  const [showAgeModal, setShowAgeModal] = useState(false);
  const [targetUrl, setTargetUrl] = useState('/');

  const handleShowNSFW = (url) => {
    setShowAgeModal(true);
    setTargetUrl(url);
  };

  const handleConfirmAge = () => {
    dispatch({
      type: 'UPDATE_NSFW',
      data: true
    });
    setNSFW(true);
    setShowAgeModal(false);

    navigate(targetUrl);
  };

  useEffect(() => {
    setNSFW(nsfw);
  }, [nsfw]);


  const [showBanner, setShowBanner] = useState(true);

  return (
    <>
      {(
        <DashboardLayout>
          <div className={styles.header}>
            {showBanner ? <div className={styles.banner}>
              <div className={styles.bannerContent}>
                <h2>YOUR PERSONALIZED UNCENSORED ROLEPLAY EXPERIENCE </h2>
                <p>What do <span className={styles.highlight}>you</span> want to do? 😛</p>
                <ul>
                  <li>• Limitless AI Chat</li>
                  <li>• Spicy roleplay</li>
                  <li>• Generate Custom AI Images</li>
                  <li>• Create your own characters</li>
                </ul>
              </div>
              <div className={styles.row}>
                <button
                  className={styles.createButton}
                  onClick={e => {
                    e.preventDefault();
                    navigate('/signup')
                  }}
                >Get Started</button>
                {(
                  <button
                    className={styles.createButton}
                    style={{ backgroundColor: '#000', border: '2px dashed #fff' }}
                    onClick={() => {
                      setInstallModal(true)
                    }}
                  >
                    Install App
                  </button>
                )}
                {/*<span>Use code SUMMER50 for 50% off!</span>*/}
              </div>
              <button onClick={setShowBanner} className={styles.closeButton}>×</button>
            </div> : <></>}
            <div className={styles.content}>
              <h2>Welcome to Mindscape</h2>
              <p>
                Bring your wildest fantasies to life. <br />
                <span className={styles.typingText}>{typingText}</span>
                <span className={styles.cursor}></span>
              </p>
              <div className={styles.callToAction}>
                {/*<Button onClick={() => navigate('/register')} inline>Sign Up</Button>*/}
              </div>
            </div>
          </div>

          <div className={styles.discoverSection}>
            <h2 className={styles.sectionTitle}>Discover our AI Characters 🔥</h2>
            <div className={styles.embla} ref={discoverRef}>
              <div className={styles.emblaContainer}>
                {/* 8, 17*/}
                {characters.slice(9, 18).reverse().map((character) => (
                  <div className={styles.emblaSlide} key={character._id}>
                    <div className={styles.characterCard}>
                      <img
                        src={updateImageUrl(character.imageUrl)}
                        alt={character.name}
                        className={styles.characterImage}
                      />
                      {/*character.nsfw && !nsfwEnabled && (
                        <NSFWOverlay onShow={handleShowNSFW} likes={character.likes} />
                      )*/}
                      <div className={styles.characterInfo}>
                        <p className={styles.characterName}>Chat with {character.name}</p>
                        <button
                          className={styles.chatButton}
                          onClick={e => {
                            e.preventDefault();
                            navigate(`/character/${character['_id']}`, { state: character })
                          }}
                        >Start Chat Now</button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <button className={styles.emblaPrev} onClick={() => scrollPrev(discoverApi)}>‹</button>
            <button className={styles.emblaNext} onClick={() => scrollNext(discoverApi)}>›</button>
          </div>

          {loggedIn ? <div className={styles.trendingSection}>
            <h2 className={styles.sectionTitle}>Trending Characters 🔥</h2>
            <div className={styles.embla} ref={trendingRef}>
              <div className={styles.emblaContainer}>
                {characters.slice(0, 9).map((character) => (
                  <div className={styles.emblaSlide} key={character._id}>
                    <div
                      className={styles.trendingCard}
                    >
                      <img
                        src={updateImageUrl(character.imageUrl)}
                        alt={character.name}
                        //className={`${styles.trendingImage} ${character.nsfw && !nsfwEnabled ? styles.blurred : ''}`}
                        className={`${styles.trendingImage} ${styles.blurred}`}
                      />
                      <div
                        className={styles.trendingInfo}
                        onClick={e => {
                          e.preventDefault();
                          navigate(`/character/${character['_id']}`, { state: character })
                        }}
                      >
                        <div className={styles.trendingHeader}>
                          <h3 className={styles.trendingName}>{character.name}</h3>
                          <span className={styles.trendingLikes}>♥ {character.likes}</span>
                        </div>
                        <p className={styles.trendingDescription}>{character.description}</p>
                        <div className={styles.trendingFooter}>
                          {/*character.nsfw && <span className={styles.nsfwTag}>NSFW</span>*/}
                          <span className={styles.viewsCount}>{(formatNumber(character.messages) || 0).toLocaleString()} 💬</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <button className={styles.emblaPrev} onClick={() => scrollPrev(trendingApi)}>‹</button>
            <button className={styles.emblaNext} onClick={() => scrollNext(trendingApi)}>›</button>
          </div> : <></>}

          {/*loggedIn ? <div className={styles.featuredCreators}>
            <h2>Featured Creators</h2>
            <div className={styles.creatorsContainer}>
              <div className={styles.embla}>
                <div className={styles.creators}>
                  {[
                    { username: "nsfwBabe", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/1.webp" },
                    { username: "cryptic_8", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/19.avif" },
                    { username: "lunaWave", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/13.jpg" },
                    { username: "nexubundas9", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/11.jpg" },
                  ].map((creator, index) => (
                    <div className={styles.creator} key={index}>
                      <img src={creator.pfp} alt={creator.username} className={styles.creatorImage} />
                      <p className={styles.creatorName}>
                        {creator.username} {(index === 0 || index === 2 || index === 3) && <span className={styles.crown}>👑</span>}
                      </p>
                      <button
                        className={styles.creatorButton}
                        onClick={e => {
                          e.preventDefault();
                          navigate(`/creator/${creator.username}`)
                        }}
                      >See Characters</button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div> : <></>*/}


          <div className={styles.exploreSection}>
            {loggedIn ? <><div className={styles.exploreHeader}>
              <h2>Explore 🔍</h2>
              <div className={styles.exploreRow}>
                <CustomDropdown
                  options={sortOptions}
                  value={sortBy}
                  onChange={handleSortChange}
                />
                <button
                  className={styles.discoverNew}
                  onClick={e => {
                    e.preventDefault();
                    navigate('/search');
                  }}
                >← discover new characters</button>
              </div>
            </div>
            {<div className={styles.filterSection}>
              {visibleCategories.map(category => (
                <button
                  key={category}
                  className={`${styles.filterButton} ${activeFilters.includes(category) ? styles.active : ''}`}
                  onClick={() => toggleFilter(category)}
                >
                  {category}
                </button>
              ))}
              <button
                className={styles.showMoreButton}
                onClick={() => setShowAllCategories(!showAllCategories)}
              >
                {showAllCategories ? "Show less" : "Show more"}
              </button>
            </div>}

            <div className={styles.characterGrid}>
              {exploreCharacters.map((character, index) => (
                <div
                  key={character._id}
                  //ref={index === exploreCharacters.length - 1 ? lastExploreCharacterRef : null}
                  className={styles.characterCard}
                  onClick={(e) => {
                    if (character.nsfw && !nsfwEnabled) return;

                    navigate(`/character/${character['_id']}`, { state: character });
                  }
                  }
                >
                  <div className={styles.imageContainer}>
                    <img
                      src={updateImageUrl(character.imageUrl) || "/placeholder.avif"}
                      alt={character.name}
                    //className={character.nsfw && !nsfwEnabled ? styles.blurred : ''}
                    //className={styles.blurred}
                    />
                    <NSFWOverlay onShow={(e) => {
                      e.preventDefault();
                      //handleShowNSFW(`/character/${character['_id']}`)
                      navigate(`/character/${character['_id']}`)
                    }} likes={character.likes} />
                    {/*character.nsfw && !nsfwEnabled && (
                      <NSFWOverlay onShow={handleShowNSFW} likes={character.likes} />
                    )*/}
                    <div className={styles.likes}>♥ {character.likes}</div>
                    {/*character.nsfw && <div className={styles.nsfwTag}>NSFW</div>*/}
                  </div>
                  <h3>{character.name}</h3>
                  <p>{character.description}</p>
                  <div className={styles.cardFooter}>
                    <span className={styles.messages}>{(formatNumber(character.messages) || 0).toLocaleString()} 💬</span>
                  </div>
                </div>
              ))}
            </div></> : <></>}
            {loading && <div>Loading...</div>}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '14px'
              }}
            >
              <Input
                placeholder={'Click here to discover more characters'}
                style={{ width: '375px', cursor: 'pointer' }}
                onClick={e => {
                  e.preventDefault();
                  navigate('/search')
                }}
              />
            </div>
          </div>
        </DashboardLayout>
      )}
      {
        <AgeConfirmModal
          isOpen={showAgeModal}
          onClose={() => setShowAgeModal(false)}
          onConfirm={handleConfirmAge}
        />
      }
      {
        <InstallModal
          isOpen={showInstallModal}
          onClose={() => setInstallModal(false)}
          //onConfirm={handleClearChat}
          title="Install App"
          message="To install the Mindscape app on your iPhone: tap the Share button, then 'Add to Home Screen'."
        />
      }
    </>
  );
}