import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';
import styles from '../../assets/pages/ManageMembership.module.scss';
import axiosRequest from '../../utils/request';
import axios from 'axios';

import { useLocation } from 'react-router-dom';

import { toast } from 'react-toastify';

export default function ManageMembership() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const loggedIn = useSelector(state => state.loggedIn);

  const [activeTab, setActiveTab] = useState('plan');
  const [currentPlan, setCurrentPlan] = useState('Free');
  const [username, setUsername] = useState('');
  const [bio, setBio] = useState("");
  const [sponsorMeLink, setSponsorMeLink] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');

  const user = useSelector(state => state.userData);

  console.log('User => ', user);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const jwt = localStorage.getItem('jwt');
        const endpoint = `user/${user.username}${'?isOwnProfile=true'}`;

        const { data, status } = await axiosRequest('get', endpoint, null, jwt);

        if (status == 200 && data) {

          console.log('New User Data -> ', data);

          dispatch({ type: 'UPDATE_USER_DATA', data: data});

          toast.success('Welcome to Mindscape. Checkout was successful!' );
        } else {
          throw new Error('Failed to fetch profile data');
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
        toast.error('Error fetching profile data.')
      };
    };

    const params = new URLSearchParams(location.search);
    const isCheckout = params.get('code');

    if (isCheckout) {
      fetchProfileData();
    }
  }, [location]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (user) {
      //console.log('User -> ', user);
      setUsername(user.username || '');
      setBio(user.bio || '');
      setSponsorMeLink(user.sponsorMeLink || '');
      setPreviewUrl(user.pfp || '');
    }
  }, [user]);

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    console.log('FILE -> ', file);
    if (file) {
      setProfilePicture(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('bio', bio);

      if (profilePicture instanceof File) {
        formData.append('pfp', profilePicture);
      }

      const token = localStorage.getItem("jwt");

      const { data, status } = await axiosRequest(
        'POST',
        'user/update',
        formData,
        token,
        true
      );

      if (status === 200 && data) {
        dispatch({ type: 'UPDATE_USER_DATA', data: data.user });
        toast.success('Profile updated successfully!');
      } else {
        throw new Error('Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile. Please try again.');
    }
  };

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handlePasswordUpdate = async () => {
    try {
      if (newPassword !== confirmNewPassword) {
        toast.error('New passwords do not match');
        return;
      }

      const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
      if (!newPassword.match(passwordRegex)) {
        toast.error('Password must include an uppercase character and a number, and be at least 8 characters long.');
        return;
      }

      const token = localStorage.getItem("jwt");
      const { data, status } = await axiosRequest(
        'POST',
        'user/update-password',
        { currentPassword, newPassword },
        token
      );

      if (status === 200 && data.success) {
        toast.success('Password updated successfully');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      } else {
        throw new Error(data.msg || 'Failed to update password');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      toast.error(error.message || 'Failed to update password. Please try again.');
    }
  };



  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedBilling, setselectedBilling] = useState('monthly');

  const [loading, setLoading] = useState(false);

  const getPriceId = (planType, selectedBilling) => {
    const priceIds = {
      premium: {
        monthly: 'price_1PzLJpDqfFzJp3s7vn6hphXV',
        yearly: 'price_1PzLJpDqfFzJp3s7x4NmekBS',
      },
      deluxe: {
        monthly: 'price_1PzLJpDqfFzJp3s7koEbSc98',
        yearly: 'price_1PzLJpDqfFzJp3s72Xunoozd',
      },
    };
    
    return priceIds[planType][selectedBilling];
  };
  
  const handleSubscribe = async () => {
    if (loggedIn) {
      let selectedPriceId = getPriceId(selectedPlan.toLowerCase(), selectedBilling.toLowerCase());
      window.open(`https://aibuddy.fun/membership?id=${user['_id']}&priceId=${selectedPriceId}`)
    } else {
      navigate('/login');
    }
    return;
  };

  const getDaysRemaining = () => {
    if (!user) return 0;
    const end = new Date(user.subscriptionEndDate);
    const now = new Date();
    const diff = end - now;
    return Math.max(0, Math.ceil(diff / (1000 * 60 * 60 * 24)));
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
  };

  const getPlanPrice = (plan) => {
    switch (plan) {
      case 'premium': return 9;
      case 'deluxe': return 14;
      default: return 0;
    }
  };

  const plans = [
    {
      name: 'Premium',
      monthlyPrice: 9,
      yearlyPrice: 99,
      features: [
        '5000 messages / month',
        '400 FREE Coins each month',
        'Chat History',
        'Premium Models',
        'NSFW',
        'Custom Characters',
        'Premium Creator badge'
      ]
    },
    {
      name: 'Deluxe',
      monthlyPrice: 14,
      yearlyPrice: 149,
      features: [
        '20,000 messages / month',
        '1200 FREE Coins each month',
        'Chat History',
        'Premium Models',
        'NSFW',
        'Voice',
        'Custom Characters',
        '8K Memory',
        'In-Chat Pictures',
        'Deluxe Creator badge'
      ]
    }
  ];

  const handlePlanSelect = (planName) => {
    if (planName !== currentPlan) {
      setSelectedPlan(planName);
    }
  };


  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const handleCancelPlan = async () => {
    setCancelLoading(true);
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await axiosRequest('post', 'user/cancel-subscription', {}, jwt);

      if (response.data && response.data.success) {
        toast.success(response.data.message);
        // Update the user's subscription status in Redux
        dispatch({ type: 'UPDATE_USER_DATA', data: response.data.user });
      } else {
        throw new Error('Failed to cancel subscription');
      }
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      toast.error('Failed to cancel subscription. Please try again.');
    } finally {
      setCancelLoading(false);
      setShowCancelModal(false);
    }
  };

  return (
    <DashboardLayout title={'Manage your account'}>
      <div className={styles.container}>
        {/*<header className={styles.header}>
          <h1>Account</h1>
          <p>Manage your account</p>
        </header>*/}

        <div className={styles.tabContainer}>
          <div className={styles.tabs}>
            <button
              className={`${styles.tabButton} ${activeTab === 'plan' ? styles.active : ''}`}
              onClick={() => handleTabChange('plan')}
            >
              Subscription
            </button>
            <button
              className={`${styles.tabButton} ${activeTab === 'account' ? styles.active : ''}`}
              onClick={() => handleTabChange('account')}
            >
              Account
            </button>
            <button
              className={`${styles.tabButton} ${activeTab === 'security' ? styles.active : ''}`}
              onClick={() => handleTabChange('security')}
            >
              Security
            </button>
          </div>

          <div className={styles.tabContent}>
            {activeTab === 'plan' && (<div className={styles.planTab}>
              <div className={styles.currentPlanInfo}>
                <div className={styles.daysRemaining}>
                  <svg viewBox="0 0 36 36" className={styles.circularProgress}>
                    <path
                      className={styles.circleBackground}
                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className={styles.circleProgress}
                      strokeDasharray={`${(getDaysRemaining() / 365) * 100}, 100`}
                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                  </svg>
                  <div className={styles.daysText}>
                    <span className={styles.days}>{getDaysRemaining()}</span>
                    <span>Days Remaining</span>
                  </div>
                </div>
                <div className={styles.planDetails}>
                  <h4 style={{}}>Current Plan:</h4>
                  <p className={styles.price}>{user.subscriptionPlan.charAt(0).toUpperCase() + user.subscriptionPlan.slice(1)},  ${getPlanPrice(user.subscriptionPlan)} / monthly</p>
                  {/*<p>{getPlanDescription(user.subscriptionPlan)}</p>*/}
                  <p className={styles.paymentInfo}>
                    Next ${getPlanPrice(user.subscriptionPlan)} payment on {formatDate(user.nextPaymentDate)}
                  </p>
                  <p className={styles.paymentInfo}>
                    Last payment: {formatDate(user.lastPaymentDate)}
                  </p>
                </div>
              </div>

              <h3 className={styles.otherPlansTitle}>Upgrade Plans</h3>
              <div className={styles.planComparison}>
                <div className={styles.billingToggle}>
                  <button
                    className={selectedBilling === 'monthly' ? styles.active : ''}
                    onClick={() => setselectedBilling('monthly')}
                  >
                    Monthly
                  </button>
                  <button
                    className={selectedBilling === 'yearly' ? styles.active : ''}
                    onClick={() => setselectedBilling('yearly')}
                  >
                    Yearly (Save 17%)
                  </button>
                </div>
                <div className={styles.planCards}>
                  {plans.map(plan => (
                    <div
                      key={plan.name}
                      className={`${styles.planCard} 
                        ${plan.name === currentPlan ? styles.currentPlan : ''} 
                        ${plan.name === selectedPlan ? styles.selectedPlan : ''}`}
                      onClick={() => handlePlanSelect(plan.name)}
                    >
                      <h3>{plan.name}</h3>
                      <p className={styles.price}>
                        {selectedBilling === 'monthly'
                          ? `$${plan.monthlyPrice}/month`
                          : `$${plan.yearlyPrice}/year`}
                      </p>
                      <ul>
                        {plan.features.map(feature => (
                          <li key={feature}>✓ {feature}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                <button
                  className={styles.checkoutButton}
                  disabled={!selectedPlan || selectedPlan === currentPlan}
                  onClick={() => handleSubscribe()}
                >
                  {currentPlan === 'free' ? 'Upgrade Now' : 'Change Plan'}
                </button>
              </div>
              {user.subscriptionPlan !== 'free' && (
                <button
                  className={styles.cancelButton}
                  onClick={() => {
                    if(user.subscriptionStatus == 'canceled') {
                      handleCancelPlan();
                      return;
                    } else {
                      setShowCancelModal(true)
                    }
                  }}
                  style={user.subscriptionStatus == 'canceled' ? { background: '#fff', color: '#000'} : {}}
                >
                  {user && user.subscriptionStatus == 'canceled' ? 'Reactivate Subscription' : 'Cancel Plan'}
                </button>
              )}

              {showCancelModal && (
                <div className={styles.modal}>
                  <div className={styles.modalContent}>
                    <h2>Positive?</h2>
                    <p>Are you sure you want to cancel your subscription?</p>
                    <div className={styles.modalButtons}>
                      <button
                        className={styles.cancelButton}
                        onClick={handleCancelPlan}
                        disabled={cancelLoading}
                      >
                        {cancelLoading ? 'Cancelling...' : '⚠️ Yes, Cancel'}
                      </button>
                      <button
                        className={styles.keepButton}
                        onClick={() => setShowCancelModal(false)}
                        disabled={cancelLoading}
                      >
                        No, Keep My Plan
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            )}

            {activeTab === 'account' && (
              <div className={styles.accountTab}>
                <h2>Account Information</h2>

                <div className={styles.profilePictureSection}>
                  <div className={styles.profilePicturePreview}>
                    {previewUrl.length < 1 ? <></> : <img src={previewUrl || '/default-avatar.png'} alt="" />}
                  </div>
                  <div className={styles.profilePictureUpload}>
                    <label htmlFor="profilePicture" className={styles.uploadButton}>
                      Upload New Picture
                    </label>
                    <input
                      type="file"
                      id="profilePicture"
                      accept="image/*"
                      onChange={handleProfilePictureChange}
                      style={{ display: 'none' }}
                    />
                  </div>
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <p>Your unique public name visible to community.</p>
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="bio">Bio</label>
                  <textarea
                    id="bio"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                  />
                  <p>Small bio. Written by you. Read by your fans.</p>
                </div>
                {/*<div className={styles.formGroup}>
                  <label htmlFor="sponsorMeLink">Sponsor Me Link</label>
                  <input
                    type="text"
                    id="sponsorMeLink"
                    value={sponsorMeLink}
                    onChange={(e) => setSponsorMeLink(e.target.value)}
                    placeholder="Your https://ko-fi.com or https://tipeee.com/ link."
                  />
                  <p>Paste the link to your sponsor-me page.</p>
            </div>*/}
                <button className={styles.saveButton} onClick={handleSave}>Save</button>
              </div>
            )}

            {activeTab === 'security' && (
              <div className={styles.securityTab}>
                <div className={styles.passwordSection}>
                  <h3>Update Password</h3>
                  <div className={styles.formGroup}>
                    <label htmlFor="currentPassword">Current Password</label>
                    <input
                      type="password"
                      id="currentPassword"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="newPassword">New Password</label>
                    <input
                      type="password"
                      id="newPassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="confirmNewPassword">Confirm New Password</label>
                    <input
                      type="password"
                      id="confirmNewPassword"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                  </div>
                  <button className={styles.updatePasswordButton} onClick={handlePasswordUpdate}>
                    Update Password
                  </button>
                </div>
                <div className={styles.activeDevices}>
                  <h3>Active devices</h3>
                  <DeviceList user={user}/>
                </div>
                <div className={styles.dangerZone}>
                  <h3>Danger Zone</h3>
                  <button style={{ opacity: '0.5', cursor: 'default' }} className={styles.deleteAccountButton}>Delete Account</button>
                  <p>Delete your account and all its associated data</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

// Device LIST 

function DeviceList({ user }) {
  const [currentIP, setCurrentIP] = useState(null);

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setCurrentIP(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP:', error);
      }
    };

    fetchIP();
  }, []);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  return (
    <div>
      {user.deviceInfo && user.deviceInfo.map((device, index) => (
        <ul key={index}>
          <li>
            <strong>{device.device}</strong> 
            {currentIP && device.ip === currentIP && <span> (This device)</span>}
            <p>{device.browser}</p>
            <p>{device.ip} ({device.location})</p>
            <p>Last Login: {formatDate(device.lastLoginTime)}</p>
          </li>
        </ul>
      ))}
    </div>
  );
}