import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/main.scss';
import router from './router';
import { RouterProvider } from "react-router-dom";

import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';

import { PersistGate } from 'redux-persist/integration/react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          theme={'dark'}
        />
        <RouterProvider router={router} />
      </PersistGate>
  </Provider>
);
