import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';

export default function ContentRemovalPolicy() {
  const policySections = [
    {
      title: "Introduction",
      content: "At Mindscape, we are committed to maintaining a safe and respectful experience for all our users. This Content Removal Policy outlines our approach to addressing concerns related to content that may inadvertently resemble real individuals. All content on our platform is generated exclusively through artificial intelligence technology, and any similarity to actual persons is unintentional and purely coincidental."
    },
    {
      title: "Unintentional Resemblance to Actual Persons",
      content: "Despite the AI-generated nature of our content, we acknowledge that there might be instances where the generated content unintentionally resembles actual persons. We are dedicated to promptly addressing such concerns when they arise."
    },
    {
      title: "Content Removal Process",
      content: "If a user believes that any content on our platform bears resemblance to them or another actual person, they can request its removal by contacting our support team at Support or by using the 'Contact' section in our App(s). We will thoroughly review each request and take appropriate action within a reasonable timeframe."
    },
    {
      title: "User Verification",
      content: "To ensure the accuracy and legitimacy of content removal requests, we may ask users to provide adequate evidence of their identity or relationship to the person depicted in the content. This verification process is implemented to responsibly handle requests and safeguard the rights and interests of all users."
    },
    {
      title: "Content Removal",
      content: "Upon verification and confirmation of a valid content removal request, the specified content will be removed in a timely manner from our platform. Our goal is to complete this process promptly while ensuring compliance with applicable laws and regulations."
    },
    {
      title: "Privacy",
      content: "We prioritize user privacy throughout the entire content removal process. All requests are treated as strictly confidential, and we do not disclose any personal information or details of the requests to third parties without the user's explicit consent, unless required by law."
    },
    {
      title: "Contact Information",
      content: "For any questions or clarifications regarding our Content Removal Policy, please contact us at Support or use the 'Contact' section in our App(s). We are committed to addressing concerns promptly and ensuring a positive experience for all our users."
    }
  ];

  return (
    <>
      <DashboardLayout title={'Content Removal Policy'}>
        <div style={{
          maxWidth: '100%',
          margin: '0 auto',
          padding: '20px',
          fontFamily: 'Arial, sans-serif',
          backgroundColor: '#121212a0',
        }}>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            Last updated: {new Date().toLocaleDateString()}
          </p>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            This Content Removal Policy outlines Mindscape's approach to addressing concerns 
            related to AI-generated content that may inadvertently resemble real individuals. 
            We are committed to maintaining a safe and respectful environment for all our users.
          </p>
          {policySections.map((section, index) => (
            <div key={index} style={{
              marginBottom: '20px',
              padding: '15px',
              borderRadius: '5px'
            }}>
              <h2 style={{
                color: '#fff',
                fontSize: '20px',
                marginBottom: '10px',
                fontWeight: 'bold'
              }}>{section.title}</h2>
              <p style={{
                fontSize: '14px',
                lineHeight: '1.5',
                color: '#fff'
              }}>{section.content}</p>
            </div>
          ))}
          <p style={{
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '30px',
            color: '#fff'
          }}>
            By using Mindscape, you acknowledge and agree to the terms outlined in this Content Removal Policy.
          </p>
        </div>
      </DashboardLayout>
    </>
  )
}