import { useState, useEffect } from 'react';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/UserChats.module.scss';
import axiosRequest from '../utils/request';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import formatNumber from '../utils/formatNumber';

import updateImageUrl from '../utils/updateImageUrl';

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  };
  return date.toLocaleString('en-US', options);
}

// Skeleton loader component
const SkeletonLoader = () => (
  <div className={styles.skeletonItem}>
    <div className={`${styles.skeletonAvatar} ${styles.skeletonAnimation}`}></div>
    <div className={styles.skeletonContent}>
      <div className={`${styles.skeletonHeader} ${styles.skeletonAnimation}`}></div>
      <div className={`${styles.skeletonText} ${styles.skeletonAnimation}`}></div>
      <div className={`${styles.skeletonText} ${styles.skeletonAnimation}`}></div>
    </div>
  </div>
);

export default function UserChats() {
  const navigate = useNavigate();

  const loggedIn = useSelector(state => state.loggedIn);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChats = async () => {
      const startTime = Date.now();
      try {
        const jwt = localStorage.getItem('jwt');
        const response = await axiosRequest('get', 'chats', null, jwt);
        const loadTime = Date.now() - startTime;

        if (loadTime < 500) {
          await new Promise(resolve => setTimeout(resolve, 500 - loadTime));
        }

        setChats(response.data.reverse());
      } catch (err) {
        console.error('Error fetching chats:', err);
        setError('Failed to load chats. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchChats();
  }, []);

  if (!loggedIn) {
    return null;
  }

  return (
    <DashboardLayout title={'Recent Chats'} hideFooter={true}>
      <div className={styles.chatOuterContainer}>
        <div className={styles.chatScrollContainer}>
          {loading ? (
            // Render skeleton loaders
            Array(5).fill().map((_, index) => <SkeletonLoader key={index} />)
          ) : error ? (
            <div className={styles.errorMessage}>{error}</div>
          ) : chats.length === 0 ? (
            <div className={styles.emptyState}>
              <p>You haven't started any chats yet.</p>
              <a 
                href="#" 
                className={styles.exploreLink}
                onClick={e => {
                  e.preventDefault();
                  navigate('/search')
                }}
              >Click here to explore characters to chat with</a>
            </div>
          ) : (
            <>
              {chats.map((chat) => (
                <div 
                  key={chat._id} 
                  className={styles.chatItem}
                  onClick={e => {
                    e.preventDefault();

                    let link = `/character/${chat.character['_id']}/chat`;

                    /*if(loggedIn) {
                      link = link + `/${user['_id']}`
                    }*/
                    navigate(link);
                  }}
                >
                  <img src={updateImageUrl(chat.character.imageUrl) || '/placeholder.avif'} alt={chat.character.name} className={styles.avatar} />
                  <div className={styles.chatContent}>
                    <div className={styles.chatHeader}>
                      <div className={styles.column}>
                        <h3>{chat.character.name}</h3>
                        <span className={styles.date}>
                          Created At: {formatDate(chat.lastMessage ? chat.createdAt : chat.createdAt)}
                        </span>
                      </div>
                      <button className={styles.moreButton}>⋮</button>
                    </div>
                    <p className={styles.lastMessage}>
                      {chat.lastMessage ? `${chat.lastMessage.content.length > 75 ? chat.lastMessage.content.substring(0, 75) :chat.lastMessage.content}...` : 'No messages yet'}
                    </p>
                    <div className={styles.replies}>
                      <span>💬 {formatNumber(chat.messageCount)} - Last Message: {formatDate(chat.lastMessageAt)}</span>
                    </div>
                  </div>
                </div>
              ))}
              <div className={styles.endOfList}>
                <p>You've reached the end of your conversation list.</p>
                <a 
                  href="#" 
                  className={styles.exploreLink}
                  onClick={e => {
                    e.preventDefault();
                    navigate('/for-you')
                  }}
                >Click here to explore more characters to chat with</a>
              </div>
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}