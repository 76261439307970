
const aboutData = {
  section1: `Welcome, welcome. Looks like you found Mindscape.`,
  section2: `You know all those scenarios you play in your head, those day dreams?`,
  section3: `Mindscape brings those to life.`,
  section4: `A roleplaying platform that lets you create, discover, and chat with an endless amount of AI characters.`,
  section5: `No limits. No filters. A digital realm where you can push boundries!`,
  section6: `Let's take a look...`,
  video1: 'https://s3.amazonaws.com/mindscape.is/about/about_1.mov',
  video2: 'https://s3.amazonaws.com/mindscape.is/about/about_2.mov'
};

export default aboutData;