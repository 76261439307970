import React from 'react';
import { Link } from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';

const LegalItem = ({ title, to }) => (
  <Link 
    to={to} 
    style={{
      display: 'block',
      padding: '15px',
      backgroundColor: '#2c2c2c',
      border: '1px solid #dc8deec5',
      color: 'white',
      textDecoration: 'none',
      borderRadius: '5px',
      transition: 'all 0.3s ease',
      fontWeight: 'bold'
    }}
    onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
    onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
  >
    {title}
  </Link>
);

const LegalInformation = () => {
  const legalItems = [
    { title: 'Community Guidelines', to: '/legal/guidelines' },
    { title: 'Terms of Use', to: '/legal/terms' },
    { title: 'Privacy Policy', to: '/legal/privacy' },
    { title: 'Underage Policy', to: '/legal/underage' },
    { title: 'Content Removal Policy', to: '/legal/content-removal' },
    { title: 'Blocked Content Policy', to: '/legal/blocked-content' },
    { title: 'DMCA Policy', to: '/legal/dmca' },
    { title: 'Complaint Policy', to: '/complaint' },
    { title: '18 U.S.C. 2257 Exemption', to: '/legal/usc-2257-exemption' },
  ];

  return (
    <DashboardLayout title="Legal Information">
      <div style={{
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '20px',
      }}>
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: '20px',
        }}>
          {legalItems.map((item, index) => (
            <LegalItem key={index} title={item.title} to={item.to} />
          ))}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default LegalInformation;