import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module.scss';
import { toast } from 'react-toastify';

const CustomDropdown = ({ options, value, onChange, alt, chat, create, selectedStyle, removeArrow, emojiSelector }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  let style = alt ? { color: '#fff', border: '1px solid #fff' } : (chat ? {
    backgroundColor: '#DD8DEE1A',
    color: '#fff',
    border: '1px solid #DD8DEE40',
    padding: '0px',
    borderRadius: '5px',
    marginRight: '10px',
    fontSize: '12px'
  } : (create ? 
  {

  } : {}));

  return (
    <div
      style={style}
      className={`${styles.customDropdown} ${chat ? styles.chatDropdown : (create ? styles.createDropdown : '')} ${emojiSelector ? styles.emojiSelector : ''}`}
      ref={dropdownRef}
    >
      <div
        style={selectedStyle ? selectedStyle : {}}
        className={`${styles.selectedOption} ${emojiSelector ? styles.emojiSelectedOption : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {value} <span className={styles.arrow}>{removeArrow ? '' : '▼'}</span>
      </div>
      {isOpen && (
        <ul className={`${styles.optionsList}`}>
          {options.map((option) => (
            <li
              key={option}
              className={`${styles.option} ${option === value ? styles.selected : ''}`}
              onClick={() => {
                if(chat) {
                  switch(option.toLowerCase()) {
                    case 'chat':
                      onChange(option);
                      setIsOpen(false);
                      break;
                    case 'image':
                      toast.info('In chat images coming soon 😋')
                      break;
                    default:
                      onChange(option);
                      setIsOpen(false);
                      break;
                  }

                } else {
                  onChange(option);
                  setIsOpen(false);
                }
              }}
              style={alt ? { border: '1px solid #fff' } : {}}
            >
              {option === 'Most Liked' && <span className={styles.icon}>👍</span>}
              {option === 'Popular' && <span className={styles.icon}>🔥</span>}
              {option === 'Trending' && <span className={styles.icon}>📈</span>}
              {option === 'Recent Hits' && <span className={styles.icon}>🏆</span>}
              {option === 'New' && <span className={styles.icon}>🆕</span>}
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;